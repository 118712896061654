export default function ScoreColorSvg() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 14.5C11.366 14.5 14.5 11.366 14.5 7.5C14.5 3.63401 11.366 0.5 7.5 0.5C3.63401 0.5 0.5 3.63401 0.5 7.5C0.5 11.366 3.63401 14.5 7.5 14.5ZM10.2862 8.46954C11.0954 7.90441 11.5 7.11678 11.5 6.10662C11.5 5.29426 11.2454 4.6638 10.7362 4.21523C10.2025 3.73841 9.42476 3.5 8.40284 3.5C8.1273 3.5 7.70877 3.53709 7.14723 3.61126C6.58919 3.6819 6.07474 3.71722 5.60389 3.71722C5.29696 3.71722 4.929 3.70309 4.5 3.67483L4.52616 4.07748C4.8575 4.10927 5.07897 4.12693 5.19058 4.13046C5.30917 5.27483 5.36846 6.66115 5.36846 8.2894C5.36846 9.49735 5.33358 10.3433 5.26383 10.8272C4.92551 10.9225 4.70404 10.9914 4.5994 11.0338L4.57848 11.5C5.20279 11.3834 5.76258 11.3252 6.25785 11.3252C6.97285 11.3252 7.68784 11.357 8.40284 11.4205L8.43423 11.0232C8.34355 11.002 8.24066 10.9773 8.12556 10.949C8.01395 10.9172 7.91281 10.8907 7.82212 10.8695C7.6861 10.4669 7.61809 9.85232 7.61809 9.02583C8.44121 8.97991 9.01844 8.91987 9.34978 8.8457C9.6846 8.76799 9.99676 8.6426 10.2862 8.46954ZM8.20927 5.48675C8.85102 5.48675 9.1719 5.72517 9.1719 6.20199C9.1719 6.44216 9.06378 6.62936 8.84753 6.76358C8.63478 6.89779 8.35401 6.9649 8.00523 6.9649C7.88316 6.9649 7.75062 6.96137 7.60762 6.9543L7.66517 5.52914C7.97907 5.50088 8.16044 5.48675 8.20927 5.48675Z" fill="url(#paint0_linear_1_54)"/>
      <defs>
      <linearGradient id="paint0_linear_1_54" x1="0.5" y1="7.46565" x2="14.5" y2="7.46565" gradientUnits="userSpaceOnUse">
      <stop stop-color="#c88ddb"/>
      <stop offset="1" stop-color="#90d6f8"/>
      </linearGradient>
      </defs>
    </svg>
  )
}