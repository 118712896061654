import Loading from "../../../../components/Loading/Loading";
import RankItem from "./RankItem";
import { decisionSunday, formatTimestamp } from "../../../../utils";
import { useEffect, useState } from "react";

export default function RankList(props) {
  const { rank, startTime, endTime, loading } = props;
  const [divH, setDivH] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const bodyH = window.innerHeight;
      const dom = document.getElementById("rankListDiv");
      const rect = dom?.getBoundingClientRect();
      const h = bodyH - rect?.top - 95;

      setDivH(h);
    }, 500);
  }, []);

  return (
    <div className="w-full h-full overflow-hidden flex flex-col">
      {startTime && endTime && (
        <div
          className="mt-4 py-[7px] px-2.5 text-white rounded-lg"
          style={{
            background:
              "linear-gradient(90deg, rgba(0, 24, 19, 0.5) 0%, rgba(0, 85, 79, 0.5) 100%)",
            border: "1px solid rgba(0, 252, 252, 0.3)",
            boxShadow: "0px 0px 10px rgba(0, 255, 240, 0.2)",
          }}
        >
          <div className="flex items-center before:content-[''] before:w-[5px] before:h-[5px] before:rounded-full before:bg-[#8FFFF6] before:mr-2 text-[13px] font-semibold text-[#FFFFFFCC]">
            <span>{formatTimestamp(startTime) || ""}</span>
            <span className="px-2">-</span>
            <span>{formatTimestamp(endTime) || ""}</span>
          </div>
        </div>
      )}
      <div className="mt-2 flex flex-row px-2.5" id="user-row">
        <div className="w-8 text-center text-sm text-[#FFFFFF99]">#</div>
        <div className={`flex-grow text-sm text-[#FFFFFF99] ${rank?.some(item => item?.rewardStr) ? "w-24" : "w-44" }`}>User</div>
        { rank?.some(item => item?.rewardStr) && <div className="flex-grow flex items-center text-[#FFFFFF99]">
          <img
            className="ml-1"
            width={64}
            loading="lazy"
            srcSet="/win.svg 1x, /win.svg 2x"
            src="/win.svg.png"
            alt="win"
          />
          </div>}
        <div className="w-30 text-sm text-[#FFFFFF99] whitespace-nowrap">⭐ Total</div>
      </div>
      <div
        className="overflow-auto"
        id="rankListDiv"
        style={{ height: `${divH}px` }}
      >
        {loading ? (
          <Loading color="rgba(255,255,255,0.5)" type="bubbles" showText />
        ) : (
          <ul className="pb-28 flex flex-col space-y-2 mt-2 text-white">
            {rank &&
              rank.map((item) => (
                <RankItem key={item.rankNumber} data={item} />
              ))}
          </ul>
        )}
      </div>
    </div>
  );
}
