import React, { useEffect } from "react";
import { HOST_API, BOT_USERNAME, PLATFORM } from "./config";
import axios from "./utils/axios";
import { setSession } from "./utils/jwt";
import { getPikaInfos } from "./api/commom";
import { useDispatch } from "react-redux";
import { setCommonInfo, setIsLogin } from "./store/features/commonSlice";
import { useParams } from "react-router-dom";
import { URL_GUIDE_KEY } from "./constants";
import { checkShowGuideView, seedGuideData } from "./utils";

const AppLogin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("HOST_API", HOST_API);
    console.log("BOT_USERNAME", BOT_USERNAME);
    let loginData = {};
    const urlParams = new URLSearchParams(window.location.search);
    const startParam = urlParams.get("startParam");
    console.log("startParam", startParam);
    if (window.Telegram && window.Telegram.WebApp) {
      console.log("Telegram Web App script is loaded");
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      console.log("window.Telegram:::::", window.Telegram);
      if (window.Telegram.WebApp.initData) {
        // 埋点1 - 启动Pika
        seedGuideData(1);
        let initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
        console.log("initData", window.Telegram.WebApp.initData);
        console.log("telegram_id", initDataUnsafe.user.id);
        console.log("username", initDataUnsafe.user.username);
        loginData = {
          platform: PLATFORM,
          tgWebAppData: window.Telegram.WebApp.initData,
          botUsername: BOT_USERNAME,
        };
        if (startParam && startParam !== "") {
          loginData.startParam = startParam;
        }
      }
    } else {
      console.error("Telegram Web App script is not loaded");
    }
    const username = urlParams.get("username");
    const password = urlParams.get("password");
    if (username && password) {
      console.log("username", username, "password", password);
      loginData = {
        platform: "99",
        username: username,
        password: password,
        startParam: startParam,
      };
    }

    async function fetchData() {
      console.log("loginData", loginData);
      const response = await axios.post("/v1/users/login", loginData);
      dispatch(setIsLogin(true));
      console.log(response, "aaaa====>");
      if (
        response.data.code === 0 &&
        response.data.result &&
        response.data.result.code === 0
      ) {
        let accessToken = response.data.result.accessToken;
        setSession(accessToken);
      }
      const resp = await getPikaInfos();
      dispatch(setCommonInfo(resp));
    }
    if (!checkShowGuideView()) {
      fetchData();
    }
  }, []);

  return <></>;
};

export default AppLogin;
