import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/zh-cn";
import { URL_GUIDE_KEY } from "../constants";
import { seedStep } from "../api/users";
import { BOT_USERNAME, PLATFORM } from "../config";
dayjs.locale("zh-cn");
dayjs.extend(utc);
dayjs.extend(isBetween);

/**
 * 是否 mock http 接口
 */
export function getHttpMock() {
  // 是否开启 接口 mock 模式
  const isMock = sessionStorage.getItem("isMock") === "1";
  return isMock;
}

/**
 * 格式化日期时间
 */

export function formatDateTime(str) {
  if (!isNaN(Date.parse(str))) {
    const date = new Date(str);
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    const md = patchZero(m) + "-" + patchZero(d);
    const hh = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();
    const hms = `${patchZero(hh)}:${patchZero(mm)}:${patchZero(ss)}`;
    return {
      y,
      md,
      hms,
    };
  } else {
    return false;
  }
}

function patchZero(str) {
  return Number(str) < 10 ? `0${str}` : str;
}

/**
 * 是否已过期
 */
export function hasExpired(str) {
  if (!isNaN(Date.parse(str))) {
    const currentDateTimeStamp = new Date().getTime();
    const appointedTimeStamp = new Date(str).getTime();
    if (currentDateTimeStamp > appointedTimeStamp) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * 本周的周一至周日日期格式化
 */

export function formatWeek() {
  const mon = dayjs().startOf("week").format("YYYY-MM-DD HH:mm");
  const sun = dayjs().endOf("week").format("YYYY-MM-DD 20:00");
  return {
    mon,
    sun,
  };
}

/**
 * 时间戳格式化
 */

export function formatTimestamp(timestamp) {
  const dateTime =
    timestamp && dayjs(timestamp * 1000).format("YYYY-MM-DD HH:mm");
  return dateTime;
}

/**
 * 格式化数值
 */

export function formatCount(str = "0") {
  const lastWord = str?.slice(-1);
  const others = str?.slice(0, -1);
  if (lastWord?.toLowerCase() === "k") {
    return others * 1000;
  } else if (lastWord?.toLowerCase() === "m") {
    return others * 1000 * 1000;
  } else {
    return str;
  }
}

/**
 * 判断是否为每周日20点-24点
 */

export function decisionSunday() {
  const now = dayjs();
  const start = now.startOf("day").hour(20);
  const end = now.startOf("day").hour(24);
  if (now.day() === 0 && now.isBetween(start, end)) {
    return true;
  } else {
    return false;
  }
}

/**
 * 是否显示新手引导页面
 */
export function checkShowGuideView() {
  const urlParams = new URLSearchParams(window.location.search);
  // return urlParams.get(URL_GUIDE_KEY);
  return true;
}

/**
 * 获取钱包地址的掩码
 * @param address 钱包地址
 * @param startNum 保留前几位
 * @param endNum 保留后几位
 * @param sign 掩码符号
 * @returns
 */
export function getShowAddress(
  address = "",
  startNum = 6,
  endNum = 4,
  sign = "..."
) {
  if (!address) {
    return "-";
  }
  const startStr = address?.slice(0, startNum);
  const endStr = address?.slice(address?.length - endNum);
  const result = `${startStr}${sign}${endStr}`;
  return result;
}

/**
 * 数据埋点 - 新手引导
 */
export async function seedGuideData(id) {
  const reqData = {
    platform: PLATFORM,
    tgWebAppData: window.Telegram.WebApp.initData,
    botUsername: BOT_USERNAME,
    id,
  };
  try {
    await seedStep(reqData);
  } catch (err) {
    console.log(err);
  }
}
