import { useEffect, useState } from "react";
import Loading from "../../../../components/Loading/Loading";
import TaskItem from "./TaskItem";
import TaskItemsBlock from "./TaskItemsBlock";

export default function TaskList(props) {
  const { data, onChange, loading } = props;

  const [divH, setDivH] = useState(0);

  const onItemChange = (id, item) => {
    onChange(id, item);
  };

  useEffect(() => {
    setTimeout(() => {
      const bodyH = window.innerHeight;
      const dom = document.getElementById("taskListDiv");
      const rect = dom?.getBoundingClientRect();
      const h = bodyH - rect?.top - 95;

      setDivH(h);
    }, 500);
  }, []);

  return (
    <div id="taskListDiv" className="flex h-full flex-col w-full">
      <div className="text-[#c88ddb ] text-xs mt-4">
        Some tasks require 30 seconds of synchronization.
      </div>
      {loading ? (
        <>
          <Loading color="rgba(255,255,255,0.5)" type="bubbles" showText />
        </>
      ) : (
        <div className="flex h-full flex-col w-full">
          <div className="flex overflow-hidden" style={{ height: `${divH}px` }}>
            <ul className="h-full w-full overflow-auto pb-28 flex  flex-col space-y-2 mt-4 text-white">
              {Array.isArray(data) &&
                data?.map((item, index) => (
                  <TaskItem key={index} data={item} onChange={onItemChange} />
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
