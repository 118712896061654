import ClockSvg from "../../../components/svg/ClockSvg";

export default function DescriptionInfo() {
  return (
    <div className="rounded-xl bg-[rgba(0, 0, 0, 0.4)] mb-6">
      <div
        className="mt-4 flex items-center justify-center text-smtext-center text-[19px] font-[800] leading-[2rem] bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] bg-clip-text text-transparent"
      >
        Invite Frens &amp; Get Rewards
      </div>
      <div
        className="mt-6 p-4 flex-row items-center rounded-lg"
        style={{
          background: "rgba(255, 255, 255, 0.08)"
        }}>
        <div className="font-bold">🎁 <span className="bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] bg-clip-text text-transparent">Regular Rewards</span></div>
        <div className="py-3 first:pt-0 last:pb-0 text-white text-sm">
          Invite a friend, <span className="text-[#c88ddb ] font-[700]">+100 GALAXY</span> for you
          and <span className="text-[#c88ddb ] font-[700]">+50 GALAXY</span> for your friend.
        </div>
        <div className="py-3 first:pt-0 last:pb-0 text-white text-sm">
          Invite a friend with Telegram Premium, <span className="text-[#c88ddb ] font-[700]">+500 GALAXY</span> for you
          and <span className="text-[#c88ddb ] font-[700]">+250 GALAXY</span> for your friend.
        </div>
      </div>
      <div
        className="mt-1 p-4 flex-row items-center rounded-lg"
        style={{
          background: "rgba(255, 255, 255, 0.08)"
        }}
      >

      </div>
      <div
        className="mt-1 p-4 flex-row items-center rounded-lg text-white"
        style={{
          background: "rgba(255, 255, 255, 0.08)"
        }}
      >
        <div className="font-bold">🎁 <span className="bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] bg-clip-text text-transparent">Bonus</span></div>
        <div
          className="py-2 first:pt-0 last:pb-0 text-white text-sm"
        >
          Inviting <span className="text-[#c88ddb ] font-[700]">150</span> new
          users, get extra{" "}
          <span className="text-[#c88ddb ] font-[700]">5 USDT</span>.
        </div>
        <div
          className="py-2 first:pt-0 last:pb-0 text-white text-sm"
        >
          Inviting <span className="text-[#c88ddb ] font-[700]">1500</span> new
          users, get extra{" "}
          <span className="text-[#c88ddb ] font-[700]">50 USDT</span>.
        </div>
        <div
          className="py-2 first:pt-0 last:pb-0 text-white text-sm"
          style={{ fontWeight: "400" }}
        >
          Inviting <span className="text-[#c88ddb ] font-[700]">3000</span>{" "}
          new users, get extra{" "}
          <span className="text-[#c88ddb ] font-[700]">100 USDT</span>.
        </div>
        <div
          className="py-2 first:pt-0 last:pb-0 text-white text-sm"
        >
          Inviting <span className="text-[#c88ddb ] font-[700]">6000</span> new
          users, get extra{" "}
          <span className="text-[#c88ddb ] font-[700]">200 USDT</span>.
        </div>
        <div
          className="py-3 first:pt-0 last:pb-0 text-white"
          style={{ fontWeight: "400" }}
        >
          <div className="mb-0.5 flex items-center text-[0.875rem]">
            ⚠️
            <div
              className="ml-0.5 font-[700]"
              style={{
                background:
                  "linear-gradient(rgb(118, 111, 250) 0%, rgb(147, 111, 250) 0.01%, rgb(0, 0, 0) 100%) text",
                Webkit: "transparent",
              }}
            >
              Important note
            </div>
          </div>
          Only Bonus must be completed within the season duration to receive rewards.
          <div>Regular rewards are always effective.</div>
        </div>
      </div>
    </div>
  );
}
