import { httpPost } from "../../utils/httpRequest";

/**
 * 获取我的邀请信息
 * @see https://candy-porpoise-601.notion.site/df09f7c791b84e05917e943fbbefdac5
 */
export async function getFarmerInvites() {
  try {
    const reqData = await httpPost("/v1/farming/getFarmerInvites");
    return reqData;
  } catch (err) {
    return null;
  }
}
