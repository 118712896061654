export default function MyReward(props) {
  const { data } = props;
  const { $Pika, invitedAmount, season$USDT, seasonInviteAmount } = data || {};
  return (
    <div
        className="text-white bg-[#000000B2] p-3 rounded-[15px] mt-3"
        style={{
          boxShadow: "0px 0px 20px rgba(0, 255, 255, 0.22)"
        }}
    >
      <div className="flex items-center">
        <img className="w-[9px] mr-2" src="/reward.png" alt="reward" />
        <span className="font-bold text-sm">My Reward</span>
      </div>
      <ul className="flex items-center justify-between mt-2 text-[13px]">
        <li className="bg-[#37373780] p-[10px] rounded-[10px] w-[50%] mr-3">
          <div className="text-[13px] font-bold mb-1">Total</div>
          <div className="flex items-center">
            <div className="w-[50%]">
              <div className="text-[#FFFFFFCC]">Invited</div>
              <div className="bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] text-transparent bg-clip-text font-bold text-[17px]">0</div>
            </div>
            <div className="flex-1">
              <div className="text-[#FFFFFFCC]">GWT</div>
              <div className="bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] text-transparent bg-clip-text font-bold text-[17px]">$0</div>
            </div>
          </div>
        </li>
        <li className="bg-[#37373780] p-[10px] rounded-[10px] w-[50%]">
          <div className="text-[13px] font-bold mb-1">Season</div>
          <div className="flex items-center">
            <div className="w-[50%]">
              <div>Invited</div>
              <div className="bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] text-transparent bg-clip-text font-bold text-[17px]">0</div>
            </div>
            <div className="flex-1">
              <div>USDT</div>
              <div className="bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] text-transparent bg-clip-text font-bold text-[17px]">$0</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
