export default function PointSvg() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7 14.5C10.866 14.5 14 11.366 14 7.5C14 3.63401 10.866 0.5 7 0.5C3.13401 0.5 0 3.63401 0 7.5C0 11.366 3.13401 14.5 7 14.5ZM9.78625 8.46954C10.5954 7.90441 11 7.11678 11 6.10662C11 5.29426 10.7454 4.6638 10.2362 4.21523C9.70254 3.73841 8.92476 3.5 7.90284 3.5C7.6273 3.5 7.20877 3.53709 6.64723 3.61126C6.08919 3.6819 5.57474 3.71722 5.10389 3.71722C4.79696 3.71722 4.429 3.70309 4 3.67483L4.02616 4.07748C4.3575 4.10927 4.57897 4.12693 4.69058 4.13046C4.80917 5.27483 4.86846 6.66115 4.86846 8.2894C4.86846 9.49735 4.83358 10.3433 4.76383 10.8272C4.42551 10.9225 4.20404 10.9914 4.0994 11.0338L4.07848 11.5C4.70279 11.3834 5.26258 11.3252 5.75785 11.3252C6.47285 11.3252 7.18784 11.357 7.90284 11.4205L7.93423 11.0232C7.84355 11.002 7.74066 10.9773 7.62556 10.949C7.51395 10.9172 7.41281 10.8907 7.32212 10.8695C7.1861 10.4669 7.11809 9.85232 7.11809 9.02583C7.94121 8.97991 8.51844 8.91987 8.84978 8.8457C9.1846 8.76799 9.49676 8.6426 9.78625 8.46954ZM7.70927 5.48675C8.35102 5.48675 8.6719 5.72517 8.6719 6.20199C8.6719 6.44216 8.56378 6.62936 8.34753 6.76358C8.13478 6.89779 7.85401 6.9649 7.50523 6.9649C7.38316 6.9649 7.25062 6.96137 7.10762 6.9543L7.16517 5.52914C7.47907 5.50088 7.66044 5.48675 7.70927 5.48675Z" fill="url(#paint0_linear_108_63)"/>
      <defs>
      <linearGradient id="paint0_linear_108_63" x1="0" y1="7.46565" x2="14" y2="7.46565" gradientUnits="userSpaceOnUse">
      <stop stopColor="#c88ddb"/>
      <stop offset="1" stopColor="#90d6f8"/>
      </linearGradient>
      </defs>
    </svg>
  )
}