export default {
    "1": {
        "id": 1,
        "type": 2,
        "en": "Follow Galaxy X",
        "cn": "绑定Twitter",
        "avatar": "icon-twitter.svg",
        "link": "https://x.com/playGALAXY",
        "noticeServer": 1,
        "needCheck": 0,
        "sort": 7
    },
    "2": {
        "id": 2,
        "type": 2,
        "en": "Join Survivor TG channel",
        "cn": "加入Survivor新频道",
        "avatar": "icon-Telegram.svg",
        "link": "https://t.me/SurvivorAnn",
        "noticeServer": 0,
        "needCheck": 1,
        "sort": 10
    }
}