import { useState } from "react";
import GuideCheckModal from "../../../../components/GuideModal/CheckModal";
import { checkTask } from "../../../../api/task";
import { showToast } from "../../../../components/showToast";
import { COMMON_TOAST_TYPE_WARNNING } from "../../../../components/showToast/constance";

export default function Content({title, onClick, onClose, taskId, onChange}) {
  const [loading, setLoading] = useState(false);

  const handleCheck = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const resp = await checkTask(taskId);
        const currentTask = resp?.taskDatas?.[taskId]
        if (currentTask?.state) {
          setLoading(false);
          onClose?.();
          onChange?.(taskId, currentTask);
        } else {
          setLoading(false);
          showToast("Task failed, conditions not met", COMMON_TOAST_TYPE_WARNNING);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    // setLoading(true);
    // onClose?.();
  }

  return (
    <GuideCheckModal
      title={title}
      loading={loading}
      onClick={handleCheck}
      onClose={onClose}
    />
  )
}
