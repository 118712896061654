import { useEffect, useState } from "react";
import { createShakeAni } from "../../utils/cssAni";
import { checkTaskCompleted } from "./biz";
import { STEP_DOM_ID_DIC } from "./constance";
import TaskItem from "./TaskItem";
import GuideCheckModal from "./CheckModal";
import { checkStep } from "../../api/users";
import { BOT_USERNAME, PIKA_CHANNEL, PLATFORM } from "../../config";
import { showToast } from "../showToast";
import { COMMON_TOAST_TYPE_WARNNING } from "../showToast/constance";
import { seedGuideData } from "../../utils";

function ModalContent(props) {
  const { data, onCompleted, onChange, winH } = props;
  const [showCheckModal, setShowCheckModal] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [loading, setLoading] = useState(false);

  function getNoneCompleted() {
    let result;
    for (let i in data) {
      if (!data?.[i]?.isCompleted) {
        result = STEP_DOM_ID_DIC[i];
        break;
      }
    }
    return result;
  }

  useEffect(() => {
    const noneCompleteId = getNoneCompleted();
    setBtnDisabled(noneCompleteId);
  }, [data]);

  async function onCheck(id, successfulFn) {
    try {
      const resp = await checkStep({
        platform: PLATFORM,
        tgWebAppData: window.Telegram.WebApp.initData,
        botUsername: BOT_USERNAME,
        id: id,
      });
      const { steps } = resp;
      if (steps[id]) {
        if (id === 1) {
          // 埋点2 -  完成Follow Pika X任务
          seedGuideData(2);
        } else if (id === 2) {
          // 埋点4 -  完成Join Survivor TG Channel任务
          seedGuideData(4);
        }
      }

      if (id === 2 && !steps[id]) {
        showToast("Failed to join channel", COMMON_TOAST_TYPE_WARNNING);
        return;
      }

      onChange?.(steps);
      successfulFn?.();
    } catch (err) {
      if (id === 2) {
        showToast("Failed to join channel", COMMON_TOAST_TYPE_WARNNING);
      }
    }
  }

  function onTaskClick(itemData) {
    const { isCompleted, link, id } = itemData;
    if (!isCompleted) {
      if (Number(id) === 2) {
        setShowCheckModal(true);
        window.Telegram.WebApp.openTelegramLink(link);
      } else {
        window.Telegram.WebApp.openLink(link);
        onCheck(1);
      }
    }
  }

  function onNext() {
    // 埋点5 - 点击NextStep按钮
    seedGuideData(5);
    const resultId = getNoneCompleted();
    if (resultId) {
      createShakeAni(resultId);
    } else {
      onCompleted();
    }
    setShowTip(true);
  }

  return (
    <div className="flex flex-col h-full w-full items-center justify-between">
      <div
        className={`flex flex-col w-full items-center ${
          winH >= 600 ? "mt-[330px]" : "mt-[290px]"
        } `}
      >
        {/* <img
          className="w-[279px] h-[231px] relative z-10"
          src="./main-img.png"
        />
        <img
          className="w-[347px] h-[80px] mt-[-50px]"
          src="./guide-info-block.png"
        /> */}
        {/* <p className="mt-[10px] mb-[15px] text-white text-[26px] font-extrabold font-sans drop-shadow-[0_0px_10px_rgba(255, 255, 255, 0.7)]">
          Join Our Community
        </p> */}
        <TaskItem
          id={STEP_DOM_ID_DIC[data?.[1]?.id]}
          onClick={onTaskClick}
          data={data[1]}
          src="./twitter-icon.png"
        />
        <TaskItem
          id={STEP_DOM_ID_DIC[data?.[2]?.id]}
          onClick={onTaskClick}
          data={data[2]}
          src="./tg-icon.png"
        />
      </div>
      <div className="w-full mb-[68px] flex flex-col items-center">
        {showTip && (
          <p className="text-[#FFEE92] text-base font-semibold font-sans ">
            Please complete taks first
          </p>
        )}
        <button
          className="w-[345px] h-[47px] rounded-[10px] text-[19px] font-extrabold"
          style={{
            background: btnDisabled
              ? "#6A7777"
              : "linear-gradient(90deg, #c88ddb 0%, #90d6f8 100%)",
          }}
          onClick={onNext}
        >
          Next Step
        </button>
        {/* <img className="w-【375px】 h-[95px]" src="/guide-earn-text.png" /> */}
      </div>

      {showCheckModal && (
        <GuideCheckModal
          title={data?.[2]?.en}
          loading={loading}
          onClick={async () => {
            if (!loading) {
              // 埋点3 - 点击Check按钮
              seedGuideData(3);
              setLoading(true);
              try {
                await onCheck(2, () => {
                  setShowCheckModal(false);
                });
                setLoading(false);
              } catch (err) {
                setLoading(false);
              }
            }
          }}
          onClose={() => {
            setShowCheckModal(false);
          }}
        />
      )}
    </div>
  );
}

export default ModalContent;
