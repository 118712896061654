import EarnBlock from "./components/EarnBlock";
import { useEffect, useState } from "react";
import { queryGameInfos } from "../../api/game";
import PointSvg from "../../components/svg/PointSvg";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../store/features/userSlice";
import CountUp from "react-countup";
import PlayAndEarnTemplates from "../../constants/PlayAndEarnTemplates";
import { sortBy } from "lodash";

function Content() {
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);

  const getData = async () => {
    try {
      // const res = await queryGameInfos();
      const list = Object.keys(PlayAndEarnTemplates)?.map((k) => {
        return PlayAndEarnTemplates[k];
      });
      setList(sortBy(list, (o) => o.Sort));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    dispatch(fetchUser());
  }, []);

  return (
    <div className="container select-none mx-auto w-full max-w-3xl">
      <div className="flex items-center justify-between my-4 text-[19px] font-[900] subpixel-antialiased text-white">
        {/* 🔥 Friend Boost Bounty🔥 */}
        Play and earn
        <div className="flex items-center space-x-4 bg-[#00000033] px-2.5 py-[5px] rounded-xl border border-[#FFFFFF1A]">
          <div className="flex items-center space-x-1">
            <PointSvg />
            <span className="text-base bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] text-transparent bg-clip-text">
              {userInfo?.$Pika || 0}
              {/* <CountUp start={0} end={userInfo?.$Pika?.indexOf("k") > -1 ? userInfo?.$Pika?.slice(0, -1)*1000 : userInfo?.$Pika} /> */}
            </span>
          </div>
          <div className="relative inline-flex items-center">
            <div
              style={{
                clipPath:
                  "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                position: "relative",
                width: "24px",
                height: "24px",
                overflow: "hidden",
                zIndex: "10",
              }}
            >
              <img
                alt="avatar"
                loading="lazy"
                width="24"
                height="24"
                decoding="async"
                data-nimg="1"
                srcSet="/pika-avatar.jpg 1x, /pika-avatar.jpg 2x"
                src="/pika-avatar.jpg"
                style={{
                  color: "transparent",
                  position: "absolute",
                  inset: "0px",
                }}
              />
            </div>
            <div
              style={{
                clipPath:
                  "polygon(25% 6%, 75% 6%, 100% 50%, 75% 94%, 25% 94%, 0% 50%)",
                position: "absolute",
                width: "24px",
                height: "24px",
                overflow: "hidden",
                background:
                  "linear-gradient(180deg, #00E0FF 0%, #00FFB3 39.5%, #000000 100%)",
                zIndex: "5",
              }}
            ></div>
          </div>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-1 gap-4 pb-4">
          {list &&
            list.map((item, index) => <EarnBlock key={index} data={item} />)}
          {/* <div className="rounded-lg bg-white p-3 shadow-lg">
            <div className="flex-col">
              <div className="mb-3 flex items-center justify-between">
                <div className="flex items-center">
                  <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1">
                    <img
                      alt="/fb-winner.svg"
                      loading="lazy"
                      width="11"
                      height="11"
                      decoding="async"
                      data-nimg="1"
                      className="max-h-[80%] ml-2"
                      src="/fb-winner.svg"
                      style={{ color: "transparent" }}
                    />
                    <span className="flex-1 text-inherit px-1 pl-0.5 py-1 font-medium">
                      <div className="mx-1 font-medium">20 Winners</div>
                    </span>
                  </div>
                  <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1 border-[#669AFF] ml-2">
                    <img
                      alt="/friend-referral.svg"
                      loading="lazy"
                      width="11"
                      height="11"
                      decoding="async"
                      data-nimg="1"
                      className="max-h-[80%] ml-2"
                      src="/friend-referral.svg"
                      style={{ color: "transparent" }}
                    />
                    <span className="flex-1 text-inherit px-1 pl-0.5 py-1 font-medium">
                      <div className="mx-1 bg-gradient-to-b from-[#669AFF] to-[#857AFF] bg-clip-text text-transparent">
                        Rank by Task Referral
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="float-left w-1/3">
                  <div
                    className="relative shadow-black/5 shadow-none rounded-large bg-cover"
                    style={{ maxWidth: "120px" }}
                  >
                    <img
                      src="/product/whycoin.webp"
                      className="relative z-10 opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 shadow-none transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-lg"
                      width="120"
                      height="120"
                      alt='🚀 20,000 WGS Points Giveaway!｜Whats.games. **For leaderboard winners:**

20 winners, 1000 Points each.


**Introducing WGS Points**


Points will be the key to claim $WGS airdrop, it will be converted automatically when the 50 M $WGS airdrop season event starts.

More points, more $WGS. $WGS can be converted to USDT in the future.


🚀 **Daily 5,000,000 $WGS Airdrop for 100-day!**


Everyone can grab their share of 50M $WGS total prize pool!

Your points will be magically transformed into $WGS everyday, the $WGS quota you can get based on your earned points of the total-user-earned points. Your points are shining brightly as $WGS in your game account when you can click "Get Reward"!

Note: Remember, all users need to click "Get Reward" daily. If you don&apos;t claim what you earned yesterday, it will expire, and you&apos;ll only be able to claim today&apos;s rewards


**How to claim more $WGS?**


1️⃣ Spin the Meme Slot daily 🎰

2️⃣ Complete daily tasks to earn more spins 📈

3️⃣ Invite new friends! 👯&zwj;♂️

4️⃣ Become a VIP membership to boost your earnings 10 times!!


**About Whats.games（WGS）**


Whats.games is an innovative social gambling game brand based on TON, committed to providing users with a unique Top1 Meme gaming experience by combining meme culture with gambling games.


🎮 Play, 💵 Earn, and 🌟 Grow! Enjoy Meme Games &amp; Earn Easily on Telegram Mini App 💎

🚀  [t.me/Whatsgamesbot/Memeslots](t.me/Whatsgamesbot/Memeslots)'
                      data-loaded="true"
                    />
                  </div>
                </div>
                <div className="float-left w-2/3">
                  <div className="ml-3">
                    <p className="mb-1 font-bold">
                      🚀 20,000 WGS Points Giveaway!｜Whats.games
                    </p>
                    <div className="flex flex-row items-center justify-between pb-2">
                      <div className="flex space-x-1">
                        <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1">
                          <span className="flex-1 text-inherit px-1 py-1 font-medium">
                            In-app Points
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className="clear-both text-xs text-gray-500">
                      by Whats.games
                    </p>
                  </div>
                </div>
                <div className="clear-both"></div>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <div className="text-sm font-[600]">Ends in</div>
                <div className="flex items-center">
                  <div className="px-1 py-0.5 leading-4">
                    <span className="text-xs font-bold mt-0.5">02</span>
                  </div>
                  <div className="mx-0.5 text-xs text-[#8b8680] mt-0.5">D</div>
                  <div className="mr-1 font-bold text-[#8b8680]">:</div>
                  <div className="px-1 py-0.5 leading-4">
                    <span className="text-xs font-bold mt-0.5">03</span>
                  </div>
                  <div className="mx-0.5 text-xs text-[#8b8680] mt-0.5">H</div>
                  <div className="mr-1 font-bold text-[#8b8680]">:</div>
                  <div className="px-1 py-0.5 leading-4">
                    <span className="text-xs font-bold mt-0.5">02</span>
                  </div>
                  <div className="mx-0.5 text-xs text-[#8b8680] mt-0.5">M</div>
                  <div className="mr-1 font-bold text-[#8b8680]">:</div>
                  <div className="px-1 py-0.5 leading-4">
                    <span className="text-xs font-bold mt-0.5">45</span>
                  </div>
                  <div className="mx-0.5 text-xs text-[#8b8680] mt-0.5">S</div>
                </div>
              </div>
            </div>
            <div className="relative z-10 px-3">
              <button
                className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full py-3 text-sm font-bold bg-indigo-500 text-white"
                type="button"
              >
                <div className="flex items-center">
                  Earn
                  <img
                    alt="scoreWhite"
                    loading="lazy"
                    width="14"
                    height="14"
                    decoding="async"
                    data-nimg="1"
                    className="mx-1"
                    src="/score-white.svg"
                    style={{ color: "transparent" }}
                  />
                  +100
                </div>
              </button>
            </div>
          </div>
          <div className="rounded-lg bg-white p-3 shadow-lg">
            <div className="flex-col">
              <div className="mb-3 flex items-center justify-between">
                <div className="flex items-center">
                  <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1">
                    <img
                      alt="/fb-winner.svg"
                      loading="lazy"
                      width="11"
                      height="11"
                      decoding="async"
                      data-nimg="1"
                      className="max-h-[80%] ml-2"
                      src="/fb-winner.svg"
                      style={{ color: "transparent" }}
                    />
                    <span className="flex-1 text-inherit px-1 pl-0.5 py-1 font-medium">
                      <div className="mx-1 font-medium">500 Winners</div>
                    </span>
                  </div>
                  <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1 ml-2">
                    <img
                      alt="/leadboard.svg"
                      loading="lazy"
                      width="11"
                      height="11"
                      decoding="async"
                      data-nimg="1"
                      className="max-h-[80%] ml-2"
                      src="/leadboard.svg"
                      style={{ color: "transparent" }}
                    />
                    <span className="flex-1 text-inherit px-1 pl-0.5 py-1 font-medium">
                      <div className="mx-1">Pick By Boost Rank</div>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="float-left w-1/3">
                  <div
                    className="relative shadow-black/5 shadow-none rounded-large bg-cover"
                    style={{ maxWidth: "120px" }}
                  >
                    <img
                      src="/product/myster.webp"
                      className="relative z-10 opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 shadow-none transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-lg"
                      width="120"
                      height="120"
                      alt="Exclusive! Sign Up HashKey Global to Earn💎20 $HSK &amp; 500 $BBY Each. BountyBay x HashKey Global 'Sign-up &amp; Earn' Friend Boost Campaign


Total of 100k $HSK and 275k $BBY points airdrop gems are waiting for you!!! ($HSK airdrop is FCFS basis, for the first 500 BountyBay users who fulfilled the tasks ONLY!)


Let’s sign-up on HashKey Global and receive $HSK airdrop! Check-out the rules below and earn airdrop!


**Rules**

1/ Sign-up on HashKey Global.

2/ Complete the KYC process to earn 5 $HSK.

3/ Make a deposit into your HashKey Global account to earn an additional 15 $HSK.

4/ Submit your HashKey Global UID.

5/ Rewards will be distributed after the campaign ends.

6/ Get boosted by friends and be among the top 50 winners to earn an extra 500 $BBY points.


**About HashKey Global**


HashKey Global is the flagship digital asset exchange under HashKey Group, offering licensed digital asset trading services to global users. HashKey Global is licensed in under the Bermuda Monetary Authority's full Digital Asset Business investor protection regime under a Class F Full License.

Note: HashKey Global does not conduct business in the United States, mainland China, Hong Kong, and certain sanctioned countries.

For more details: [global.hashkey.com](https://global.hashkey.com/)"
                      data-loaded="true"
                    />
                  </div>
                </div>
                <div className="float-left w-2/3">
                  <div className="ml-3">
                    <p className="mb-1 font-bold">
                      Exclusive! Sign Up HashKey Global to Earn💎20 $HSK &amp;
                      500 $BBY Each
                    </p>
                    <div className="flex flex-row items-center justify-between pb-2">
                      <div className="flex space-x-1">
                        <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1">
                          <span className="flex-1 text-inherit px-1 py-1 font-medium">
                            Giveaway
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className="clear-both text-xs text-gray-500">
                      by HashKey Global
                    </p>
                  </div>
                </div>
                <div className="clear-both"></div>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <div className="text-sm font-[600]">Ends in</div>
                <div className="flex items-center">
                  <div className="px-1 py-0.5 leading-4">
                    <span className="text-xs font-bold mt-0.5">10</span>
                  </div>
                  <div className="mx-0.5 text-xs text-[#8b8680] mt-0.5">D</div>
                  <div className="mr-1 font-bold text-[#8b8680]">:</div>
                  <div className="px-1 py-0.5 leading-4">
                    <span className="text-xs font-bold mt-0.5">03</span>
                  </div>
                  <div className="mx-0.5 text-xs text-[#8b8680] mt-0.5">H</div>
                  <div className="mr-1 font-bold text-[#8b8680]">:</div>
                  <div className="px-1 py-0.5 leading-4">
                    <span className="text-xs font-bold mt-0.5">02</span>
                  </div>
                  <div className="mx-0.5 text-xs text-[#8b8680] mt-0.5">M</div>
                  <div className="mr-1 font-bold text-[#8b8680]">:</div>
                  <div className="px-1 py-0.5 leading-4">
                    <span className="text-xs font-bold mt-0.5">45</span>
                  </div>
                  <div className="mx-0.5 text-xs text-[#8b8680] mt-0.5">S</div>
                </div>
              </div>
            </div>
            <div className="relative z-10 px-3">
              <button
                className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full py-3 text-sm font-bold bg-indigo-500 text-white"
                type="button"
              >
                <div className="flex items-center">
                  Earn
                  <img
                    alt="scoreWhite"
                    loading="lazy"
                    width="14"
                    height="14"
                    decoding="async"
                    data-nimg="1"
                    className="mx-1"
                    src="/score-white.svg"
                    style={{ color: "transparent" }}
                  />
                  +100
                </div>
              </button>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div id="GroupDeal">
        <div className="pb-4 text-base font-semibold subpixel-antialiased">
          🚀 Trending Deals
        </div>
        <div className="grid grid-cols-1 gap-4 pb-8">
          <div className="rounded-lg bg-white p-3 shadow-lg">
            <div className="flex-col">
              <div className="flex items-center justify-between pb-2">
                <div id="soldNum" className="text-xs">
                  <div>
                    🔥 <span className="font-bold text-[#766ffa]">97</span>
                    <span className="ml-1 text-[#666]">Sold</span>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between pt-1">
                  <div className="flex space-x-1">
                    <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1">
                      <span className="flex-1 text-inherit px-1 py-1 font-medium">
                        NFT Pass
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="float-left w-1/3">
                  <div
                    className="relative shadow-black/5 shadow-none rounded-large bg-cover"
                    style={{ maxWidth: "120px" }}
                  >
                    <img
                      src="/product/esim-image.webp"
                      className="relative z-10 opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 shadow-none transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-lg"
                      width="120"
                      height="120"
                      alt="eSIM（High-Speed • 5G/4G Network • Hotspot (384kbps afterward)）. This product only works in the Global(Not Include Mainland China).

Coverage and Network：US

1GB / 3 Days • 5G Network • Hotspot(High-Speed)

Data Roaming: Roaming

TOP-UP OPTION: Available"
                      data-loaded="true"
                    />
                  </div>
                </div>
                <div className="float-left w-2/3">
                  <div className="ml-3">
                    <p className="mb-1 font-bold">
                      10% OFF eSIM（High-Speed • 5G/4G Network • Hotspot
                      (384kbps afterward)）
                    </p>
                    <p className="clear-both text-xs text-gray-500">
                      by Apertus eSIM
                    </p>
                    <div className="flex flex-row pt-1">
                      <div className="mr-2 font-bold">$25.18</div>
                      <div className="mt-1  text-xs line-through">$27.97</div>
                    </div>
                  </div>
                </div>
                <div className="clear-both"></div>
              </div>
            </div>
            <div className="flex">
              <div className="w-1/2">
                <div className="relative z-10 px-1">
                  <button
                    className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full font-bold bg-[#fff] text-indigo-500 border-2 border-indigo-500 py-4 text-sm"
                    type="button"
                    onClick={() => navigate("/buy")}
                  >
                    Buy
                  </button>
                </div>
              </div>
              <div className="w-1/2">
                <div className="relative z-10 px-1">
                  <div className="absolute right-[4px] top-[0px] z-10 flex items-center space-x-1 rounded-full px-2 py-1 text-xs font-bold text-white bg-gradient-to-r from-[#FF6565] to-[#FF852C]">
                    <span>+100</span>
                    <img
                      alt="score"
                      loading="lazy"
                      width="12"
                      height="12"
                      decoding="async"
                      data-nimg="1"
                      src="/score-white.svg"
                      style={{ color: "transparent" }}
                    />
                    <span>/Sale</span>
                  </div>
                  <button
                    className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full font-bold bg-indigo-500 text-white py-4 text-sm"
                    type="button"
                  >
                    Share
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-lg bg-white p-3 shadow-lg">
            <div className="flex-col">
              <div className="flex items-center justify-between pb-2">
                <div id="soldNum" className="text-xs">
                  <div>
                    🔥 <span className="font-bold text-[#766ffa]">165</span>
                    <span className="ml-1 text-[#666]">Sold</span>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between pt-1">
                  <div className="flex space-x-1">
                    <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1">
                      <span className="flex-1 text-inherit px-1 py-1 font-medium">
                        Electronic Cigarettes
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="float-left w-1/3">
                  <div
                    className="relative shadow-black/5 shadow-none rounded-large bg-cover"
                    style={{ maxWidth: "120px" }}
                  >
                    <img
                      src="/product/ec.webp"
                      className="relative z-10 opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 shadow-none transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-lg"
                      width="120"
                      height="120"
                      alt="Электронные сигареты Husky. Разнообразные и нежные вкусы: Электронные сигареты HUSKY славятся своим разнообразием и нежностью вкусов. Мы тщательно подбираем различные вкусы, включая свежие фруктовые, насыщенные табачные и сладкие десертные, чтобы


**удовлетворить разные потребности во вкусе. Содержание никотина 3.5%**: Содержание никотина в электронных сигаретах HUSKY
составляет 3.5%, что позволяет удовлетворить потребности курильщиков в никотине, а также снизить вредные вещества, поступающие в организм, делая использование. более здоровым и безопасным.


**Технология интеллектуального контроля температуры: Электронные сигареты HUSKY**
оснащены технологией интеллектуального контроля температуры, гарантирующей
оптимальную температуру для каждого затяжки, обеспечивая насыщенный и


**стабильный вкус. Длительное время работы**: Встроенный высокопроизводительный аккумулятор в
электронных сигаретах HUSKY обеспечивает длительное время работы, удовлетворяя потребности в курении в любое время суток.


**Удобный дизайн**: Электронные сигареты HUSKY имеют удобный дизайн, который
делает использование простым, легким в уходе и обслуживании, обеспечивая более
удобный и комфортный опыт курения.

Количество затяжную :8000

Мощность:650mAH

Область доставки по почте： Московская область и город Москва


---
[in English]

Husky-branded electronic cigarettes are praised for their diverse and gentle flavors. We carefully select a variety of flavors, including fresh fruit, rich tobacco, and sweet dessert flavors, to satisfy different taste preferences.


**Nicotine content of 3.5%**: The nicotine content in Husky e-cigarettes is 3.5%, which meets smokers' needs for nicotine while also reducing harmful substances entering the body, making it a healthier and safer option.


**Intelligent temperature control technology**: Husky e-cigarettes are equipped with intelligent temperature control technology, ensuring the optimal temperature for each draw, guaranteeing a full and stable flavor.


**Long working time**: The high-capacity battery built into Husky e-cigarettes ensures a long working time, fulfilling the smoking needs at any time of the day.


**Convenient design**: Husky e-cigarettes feature a convenient design that makes them easy to use, portable, and simple to maintain, providing a more comfortable and convenient smoking experience.


Number of puffs: 8000

Capacity: 650mAh

Mailable area: Moscow city and Moscow region"
                      data-loaded="true"
                    />
                  </div>
                </div>
                <div className="float-left w-2/3">
                  <div className="ml-3">
                    <p className="mb-1 font-bold">
                      12% OFF E-cigarettes｜Husky
                    </p>
                    <p className="clear-both text-xs text-gray-500">by Husky</p>
                    <div className="flex flex-row pt-1">
                      <div className="mr-2 font-bold">$9.31</div>
                      <div className="mt-1  text-xs line-through">$10.85</div>
                    </div>
                  </div>
                </div>
                <div className="clear-both"></div>
              </div>
            </div>
            <div className="flex">
              <div className="w-1/2">
                <div className="relative z-10 px-1">
                  <button
                    className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full font-bold bg-[#fff] text-indigo-500 border-2 border-indigo-500 py-4 text-sm"
                    type="button"
                  >
                    Buy
                  </button>
                </div>
              </div>
              <div className="w-1/2">
                <div className="relative z-10 px-1">
                  <div className="absolute right-[4px] top-[0px] z-10 flex items-center space-x-1 rounded-full px-2 py-1 text-xs font-bold text-white bg-gradient-to-r from-[#FF6565] to-[#FF852C]">
                    <span>+100</span>
                    <img
                      alt="score"
                      loading="lazy"
                      width="12"
                      height="12"
                      decoding="async"
                      data-nimg="1"
                      src="/score-white.svg"
                      style={{ color: "transparent" }}
                    />
                    <span>/Sale</span>
                  </div>
                  <button
                    className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full font-bold bg-indigo-500 text-white py-4 text-sm"
                    type="button"
                  >
                    Share
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-lg bg-white p-3 shadow-lg">
            <div className="flex-col">
              <div className="flex items-center justify-between pb-2">
                <div id="soldNum" className="text-xs">
                  <div>
                    🔥 <span className="font-bold text-[#766ffa]">86</span>
                    <span className="ml-1 text-[#666]">Sold</span>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between pt-1">
                  <div className="flex space-x-1">
                    <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1">
                      <span className="flex-1 text-inherit px-1 py-1 font-medium">
                        NFT Pass
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="float-left w-1/3">
                  <div
                    className="relative shadow-black/5 shadow-none rounded-large bg-cover"
                    style={{ maxWidth: "120px" }}
                  >
                    <img
                      src="/product/Screen_Shot_2024-03-29_at_11.46.40_PM.webp"
                      className="relative z-10 opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 shadow-none transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-lg"
                      width="120"
                      height="120"
                      alt="MATCHI DANCER NFT Grade B. What is **MATCHI**？


🕺MATCHI💃 is a Web3 dance fitness game containing AI, Token, and NFT elements. Players can use their mobile phone cameras to Dance to Earn anytime and anywhere. Now we are happy to inform you that you can participate in dancing mining to obtain early rewards and limited NFT.


What is **MATCHI DANCER NFT**？


Dancer NFT is a Character Pass NFT. Using NFT to play the game can get MCH rewards. MCH can be exchanged on pancakeswap. Different levels of NFT can get different rewards every day.


Tier   />>   Daily Reward    >>>     Cost


C-level   >>   8MCH    >>>     1000MCH


B-level   >>   25MCH    >>>     3000MCH


A-level   >>   50MCH    >>>     5000MCH


S-level   >>   120MCH    >>>     10000MCH


Current MCH price: 0.0583 USDT

Join TG to learn more https://t.me/matchi_games"
                      data-loaded="true"
                    />
                  </div>
                </div>
                <div className="float-left w-2/3">
                  <div className="ml-3">
                    <p className="mb-1 font-bold">
                      30% OFF MATCHI DANCER NFT B-level
                    </p>
                    <p className="clear-both text-xs text-gray-500">
                      by Matchi
                    </p>
                    <div className="flex flex-row pt-1">
                      <div className="mr-2 font-bold">$122.4</div>
                      <div className="mt-1  text-xs line-through">$174.86</div>
                    </div>
                  </div>
                </div>
                <div className="clear-both"></div>
              </div>
            </div>
            <div className="flex">
              <div className="w-1/2">
                <div className="relative z-10 px-1">
                  <button
                    className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full font-bold bg-[#fff] text-indigo-500 border-2 border-indigo-500 py-4 text-sm"
                    type="button"
                  >
                    Buy
                  </button>
                </div>
              </div>
              <div className="w-1/2">
                <div className="relative z-10 px-1">
                  <div className="absolute right-[4px] top-[0px] z-10 flex items-center space-x-1 rounded-full px-2 py-1 text-xs font-bold text-white bg-gradient-to-r from-[#FF6565] to-[#FF852C]">
                    <span>+100</span>
                    <img
                      alt="score"
                      loading="lazy"
                      width="12"
                      height="12"
                      decoding="async"
                      data-nimg="1"
                      src="/score-white.svg"
                      style={{ color: "transparent" }}
                    />
                    <span>/Sale</span>
                  </div>
                  <button
                    className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full font-bold bg-indigo-500 text-white py-4 text-sm"
                    type="button"
                  >
                    Share
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-lg bg-white p-3 shadow-lg">
            <div className="flex-col">
              <div className="flex items-center justify-between pb-2">
                <div id="soldNum" className="text-xs">
                  <div>
                    🔥 <span className="font-bold text-[#766ffa]">110</span>
                    <span className="ml-1 text-[#666]">Sold</span>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between pt-1">
                  <div className="flex space-x-1">
                    <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1">
                      <span className="flex-1 text-inherit px-1 py-1 font-medium">
                        NFT Pass
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="float-left w-1/3">
                  <div
                    className="relative shadow-black/5 shadow-none rounded-large bg-cover"
                    style={{ maxWidth: "120px" }}
                  >
                    <img
                      src="/product/Screen_Shot_2024-03-29_at_11.50.09_PM.webp"
                      className="relative z-10 opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 shadow-none transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-lg"
                      width="120"
                      height="120"
                      alt="FREE Giveaway🎁 2 x B-level ($180) &amp; 5 x C-level ($60) MATCHI Dancer NFTs! . What is **MATCHI**？


🕺MATCHI💃 is a Web3 dance fitness game containing AI, Token, and NFT elements. Players can use their mobile phone cameras to Dance to Earn anytime and anywhere. Now we are happy to inform you that you can participate in dancing mining to obtain early rewards and limited NFT.


What is **MATCHI DANCER NFT**？


Dancer NFT is a Character Pass NFT. Using NFT to play the game can get MCH rewards. MCH can be exchanged on pancakeswap. Different levels of NFT can get different rewards every day.


Tier   />>   Daily Reward    >>>     Cost


C-level   >>   8MCH    >>>     1000MCH


B-level   >>   25MCH    >>>     3000MCH


A-level   >>   50MCH    >>>     5000MCH


S-level   >>   120MCH    >>>     10000MCH


Current MCH price: 0.0583 USDT

Join TG to learn more https://t.me/matchi_games"
                      data-loaded="true"
                    />
                  </div>
                </div>
                <div className="float-left w-2/3">
                  <div className="ml-3">
                    <p className="mb-1 font-bold">
                      30% OFF MATCHI DANCER NFT C-level
                    </p>
                    <p className="clear-both text-xs text-gray-500">
                      by Matchi
                    </p>
                    <div className="flex flex-row pt-1">
                      <div className="mr-2 font-bold">$40.8</div>
                      <div className="mt-1  text-xs line-through">$58.29</div>
                    </div>
                  </div>
                </div>
                <div className="clear-both"></div>
              </div>
            </div>
            <div className="flex">
              <div className="w-1/2">
                <div className="relative z-10 px-1">
                  <button
                    className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full font-bold bg-[#fff] text-indigo-500 border-2 border-indigo-500 py-4 text-sm"
                    type="button"
                  >
                    Buy
                  </button>
                </div>
              </div>
              <div className="w-1/2">
                <div className="relative z-10 px-1">
                  <div className="absolute right-[4px] top-[0px] z-10 flex items-center space-x-1 rounded-full px-2 py-1 text-xs font-bold text-white bg-gradient-to-r from-[#FF6565] to-[#FF852C]">
                    <span>+100</span>
                    <img
                      alt="score"
                      loading="lazy"
                      width="12"
                      height="12"
                      decoding="async"
                      data-nimg="1"
                      src="/score-white.svg"
                      style={{ color: "transparent" }}
                    />
                    <span>/Sale</span>
                  </div>
                  <button
                    className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full font-bold bg-indigo-500 text-white py-4 text-sm"
                    type="button"
                  >
                    Share
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-lg bg-white p-3 shadow-lg">
            <div className="flex-col">
              <div className="flex items-center justify-between pb-2">
                <div id="soldNum" className="text-xs">
                  <div>
                    🔥 <span className="font-bold text-[#766ffa]">143</span>
                    <span className="ml-1 text-[#666]">Sold</span>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between pt-1">
                  <div className="flex space-x-1">
                    <div className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground bg-[#fff] border-1">
                      <span className="flex-1 text-inherit px-1 py-1 font-medium">
                        NFT Pass
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="float-left w-1/3">
                  <div
                    className="relative shadow-black/5 shadow-none rounded-large bg-cover"
                    style={{ maxWidth: "120px" }}
                  >
                    <img
                      src="/product/90_day_pass.webp"
                      className="relative z-10 opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 shadow-none transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-lg"
                      width="120"
                      height="120"
                      alt="TypoX Elite NFT 90-Day Pass. What is **TypoX Elite NFT Pass**
The TypoX Elite NFT Pass offers a new twist on traditional subscription models, providing options for **30-day and 90-day passes**, but with enhanced flexibility unique to the digital asset space. These passes, governed by the ERC721 protocol, are transferable when unused, allowing them to be traded or gifted. It’s important to note, however, that a transfer is no longer possible once an NFT Pass is bonded to a user’s account. Please remember, simply holding a TypoX Elite NFT Pass does not activate its benefits; it must be bonded with your account to unlock its full potential and advantages

**Elite NFT Pass Benefits:**
Unlimited TypoX AI Usage, including various features like search, and preview.

Redeem your Elite NFT pass to boost your TypoX loyalty score and participate in the loyalty drop.

Website: https://www.typox.ai/

**Original price:**
90-day Pass: 25u"
                      data-loaded="true"
                    />
                  </div>
                </div>
                <div className="float-left w-2/3">
                  <div className="ml-3">
                    <p className="mb-1 font-bold">
                      30% OFF TypoX Elite 90-Day NFT Pass{" "}
                    </p>
                    <p className="clear-both text-xs text-gray-500">
                      by TypoX AI
                    </p>
                    <div className="flex flex-row pt-1">
                      <div className="mr-2 font-bold">$17.5</div>
                      <div className="mt-1  text-xs line-through">$25</div>
                    </div>
                  </div>
                </div>
                <div className="clear-both"></div>
              </div>
            </div>
            <div className="flex">
              <div className="w-1/2">
                <div className="relative z-10 px-1">
                  <button
                    className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full font-bold bg-[#fff] text-indigo-500 border-2 border-indigo-500 py-4 text-sm"
                    type="button"
                  >
                    Buy
                  </button>
                </div>
              </div>
              <div className="w-1/2">
                <div className="relative z-10 px-1">
                  <div className="absolute right-[4px] top-[0px] z-10 flex items-center space-x-1 rounded-full px-2 py-1 text-xs font-bold text-white bg-gradient-to-r from-[#FF6565] to-[#FF852C]">
                    <span>+100</span>
                    <img
                      alt="score"
                      loading="lazy"
                      width="12"
                      height="12"
                      decoding="async"
                      data-nimg="1"
                      src="/score-white.svg"
                      style={{ color: "transparent" }}
                    />
                    <span>/Sale</span>
                  </div>
                  <button
                    className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-4 min-w-20 h-10 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full font-bold bg-indigo-500 text-white py-4 text-sm"
                    type="button"
                  >
                    Share
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default function Page() {
  return (
    <div className="pb-28">
      {/* <Top /> */}
      <Content />
    </div>
  );
}
