import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { queryUserAssetsInfo } from "../../api/assets";

const initialState = {
  assetsInfo: {},
  loading: true,
  usdtInfo: {
    index: 0,
    usdt: 10,
    text: ""
  }
};

export const fetchAssets = createAsyncThunk(
  'assets/fetchAssets',
  async () => {
    const res = await queryUserAssetsInfo();
    return res;
  }
)

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setAssetsInfo: (state, action) => {
      state.assetsInfo = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setUsdtInfo: (state, action) => {
      state.usdtInfo = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.assetsInfo = action.payload
        state.loading = false
      })
      .addCase(fetchAssets.rejected, (state, err) => {
        console.log('assetsError', err)
        state.loading = false
      })
  }
});

export const { setAssetsInfo, setUsdtInfo } = assetsSlice.actions;

// 默认导出
export default assetsSlice.reducer;