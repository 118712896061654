import { useTonConnectModal } from "@tonconnect/ui-react"
import { useRippleEff } from "../../../hooks/useRippleEff";

export default function ConnectBtn() {
  const { open } = useTonConnectModal();
  useRippleEff();

  return (
    <button
      type="button"
      className="relative w-full rounded-lg py-3.5 text-black font-[700] text-[17px] overflow-hidden ripple"
      style={{
        background: `linear-gradient(90deg, #c88ddb 0%, #90d6f8 100%)`
      }}
      onClick={open}
    >
      Connect Wallet
    </button>
  )
}