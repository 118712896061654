export default function GameActiveSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 5V0H8.5V5H4.33333L2.33333 6.71171L0 16.2973L1 17.6667H5.33333L6.66667 15.2703H12.6667L14 17.6667H18L19 16.2973L16.6667 6.71171L14.6667 5H10.5ZM15.8926 9.58301C15.8926 8.67155 15.4368 8.21582 14.5254 8.21582H14.0127V7.70312H12.9873V8.21582H12.4746C11.5632 8.21582 11.1074 8.67155 11.1074 9.58301V9.75391C11.1074 10.6654 11.5632 11.1211 12.4746 11.1211H12.9873V11.9756H12.4746C12.2467 11.9756 12.1328 11.8617 12.1328 11.6338H11.1074C11.1074 12.5452 11.5632 13.001 12.4746 13.001H12.9873V13.5137H14.0127V13.001H14.5254C15.4368 13.001 15.8926 12.5452 15.8926 11.6338V11.4629C15.8926 10.5514 15.4368 10.0957 14.5254 10.0957H14.0127V9.24121H14.5254C14.7533 9.24121 14.8672 9.35514 14.8672 9.58301H15.8926ZM14.5254 11.9756H14.0127V11.1211H14.5254C14.7533 11.1211 14.8672 11.235 14.8672 11.4629V11.6338C14.8672 11.8617 14.7533 11.9756 14.5254 11.9756ZM12.4746 9.24121H12.9873V10.0957H12.4746C12.2467 10.0957 12.1328 9.98177 12.1328 9.75391V9.58301C12.1328 9.35514 12.2467 9.24121 12.4746 9.24121ZM7.69727 9.84961H6.23242V8.38477H4.76758V9.84961H3.30273V11.3145H4.76758V12.7793H6.23242V11.3145H7.69727V9.84961Z" fill="url(#paint0_linear_4482_979)"/>
      <defs>
      <linearGradient id="paint0_linear_4482_979" x1="0" y1="8.83333" x2="19" y2="8.83333" gradientUnits="userSpaceOnUse">
      <stop stop-color="#c88ddb"/>
      <stop offset="1" stop-color="#90d6f8"/>
      </linearGradient>
      </defs>
    </svg>
  )
}