import ReactLoading from "react-loading";

export default function Loading({ type, color, delay, showText = false }) {
  return (
    <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ReactLoading color={color} height={'100%'} type={type} delay={delay} />
      </div>
      { showText && <div className={`text-[${color}] text-center text-sm`}>Data is loading, please be patient!</div> }
    </>
  )
}