import CountUp from "react-countup"
import { Tooltip } from "react-tippy";
import PointSvg from "../../../components/svg/PointSvg"
import QuestionMarkSvg from "../../../components/svg/QuestionMarkSvg";
import RightArrowSvg from "../../../components/svg/RightArrowSvg";

function PikaDesc() {
  return (
    <div className="text-left">
      <h4 className="font-medium mb-2">About $GALAXY</h4>
      <p>
        ※ $GALAXY is the native token of the GALAXY platform on the TON ecosystem. $GALAXY empowers players by transforming their gaming achievements into tangible rewards.
      </p>
      <p>
        ※ By completing in-game tasks, inviting friends, or participating in daily click activities, players can earn $GALAXY tokens, creating an engaging and rewarding gaming experience.
      </p>
      <p>※ As a holder of $GALAXY, you can directly receive airdrop of GALAXY governance token.</p>
    </div>
  )
}

function GWTDesc() {
  return (
    <div className="text-left">
      <h4 className="font-medium mb-2">$GWT in the Galaxy</h4>
      <p>
        ※ $GWT can be obtained in the RANK competition.
      </p>
    </div>
  )
}

function UserInfo({ $GWT, $Pika }) {
  return (
    // <div
    //   className="mt-3 flex rounded-lg border-2 border-[#FFFFFF4D] p-[8px]"
    //   style={{
    //     background:
    //       "linear-gradient(277.76deg, rgb(239, 239, 255) 1.35%, rgba(239, 239, 255, 0.1) 99%)",
    //   }}
    // >
    //   <div className="flex-1">
    //     <div
    //       className="justify-center px-2 text-[20px] font-bold"
    //       style={{
    //         background:
    //           "linear-gradient(102.23deg, rgb(101, 190, 255) -28.9%, rgb(118, 111, 250) 86.85%) text",
    //         Webkit: "transparent",
    //       }}
    //     >
    //       <div className="flex justify-center">670</div>
    //     </div>
    //     <div
    //       className="justify-center px-2 text-[14px] text-[#180940]"
    //       style={{ fontWeight: "400" }}
    //     >
    //       <div className="flex justify-center">$CPOINT</div>
    //     </div>
    //   </div>
    //   <hr className="mx-3 my-2 h-auto border-1 border-[#0000001A]" />
    //   <div className="flex-1">
    //     <div
    //       className="justify-center px-2 text-[20px] font-bold"
    //       style={{
    //         background:
    //           "linear-gradient(102.23deg, rgb(101, 190, 255) -28.9%, rgb(118, 111, 250) 86.85%) text",
    //         Webkit: "transparent",
    //       }}
    //     >
    //       <div className="flex justify-center">170</div>
    //     </div>
    //     <div
    //       className="justify-center px-2 text-[14px] text-[#180940]"
    //       style={{ fontWeight: "400" }}
    //     >
    //       <div className="flex justify-center">$Boost</div>
    //     </div>
    //   </div>
    //   <hr className="mx-3 my-2 h-auto border-1 border-[#0000001A]" />
    //   <div className="flex-1">
    //     <div
    //       className="justify-center px-2 text-[20px] font-bold"
    //       style={{
    //         background:
    //           "linear-gradient(102.23deg, rgb(101, 190, 255) -28.9%, rgb(118, 111, 250) 86.85%) text",
    //         Webkit: "transparent",
    //       }}
    //     >
    //       <div className="flex justify-center">0</div>
    //     </div>
    //     <div
    //       className="justify-center px-2 text-[14px] text-[#180940]"
    //       style={{ fontWeight: "400" }}
    //     >
    //       <div className="flex justify-center">Badges</div>
    //     </div>
    //   </div>
    // </div>
    <div
      className="mt-5 p-5 text-white rounded-xl flex items-start justify-around w-full"
      style={{
        background: `linear-gradient(0deg, rgba(20, 20, 20, 0.8), rgba(20, 20, 20, 0.8)), linear-gradient(244.58deg, rgba(0, 42, 51, 0.8) 17.78%, rgba(4, 37, 44, 0.8) 82.22%)`
      }}
    >
      <div className="flex flex-col items-center space-y-1">
        <span
          className="text-[rgba(255, 255, 255, 0.7)] font-[700]text-center text-xl font-[700] leading-[2rem] bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] bg-clip-text text-transparent"
        >
          {/* <CountUp start={0} end={$GWT} duration={1.5} /> */}
          {$GWT || 0}
        </span>
        <div className="cursor-pointer flex items-center space-x-1">
          <img
            width={16}
            height={16}
            loading="lazy"
            srcSet="/i-GWT.png 1x, /i-GWT.png 2x"
            src="/i-GWT.png"
            alt="GWT"
          />
          <span className="pr-1 text-sm">$GWT</span>
          <Tooltip
            trigger="mouseenter"
            touchHold
            html={<GWTDesc />}
            arrow={true}
            animation="scale"
            position="bottom"
          >
            <QuestionMarkSvg />
          </Tooltip>
        </div>
        <a
          href="https://x.com/GalaxyWarNFT"
          target="_blank"
          rel="noreferrer"
          className="flex items-center space-x-1 text-[11px] text-slate-300 hover:underline underline-offset-2"
        >
          <span>Learn more about $GWT</span>
          <RightArrowSvg/>
        </a>
      </div>
      <div className="h-[80px] w-px bg-[#FFFFFF33]"></div>
      <div className="flex flex-col items-center space-y-1">
        <span
          className="text-[rgba(255, 255, 255, 0.7)] font-[700]text-center text-xl font-[700] leading-[2rem] bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] bg-clip-text text-transparent"
        >
          {/* <CountUp start={0} end={$Pika?.indexOf("k") > -1 ? $Pika?.slice(0, -1)*1000 : $Pika} duration={1.5} /> */}
          {$Pika || 0}
        </span>
        <div className="cursor-pointer flex items-center space-x-1">
          <img
            width={16}
            height={16}
            loading="lazy"
            srcSet="/icon-c-color.png 1x, /icon-c-color.png 2x"
            src="/icon-c-color.png"
            alt="GALAXY"
          />
          <span className="pr-1 text-sm">$GALAXY</span>
          <Tooltip
            trigger="mouseenter"
            touchHold
            html={<PikaDesc />}
            arrow={true}
            animation="scale"
            position="bottom"
          >
            <QuestionMarkSvg />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
