import { configureStore } from "@reduxjs/toolkit";
import assetsSlice from "./features/assetsSlice";
import userSlice from "./features/userSlice";
import commonSlice from "./features/commonSlice";

const store = configureStore({
  reducer: {
    assets: assetsSlice,
    user: userSlice,
    common: commonSlice,
  },
});

export default store;
