export default {
    "9": {
        "id": 9,
        "Title": "GalaxyFlight Quarterly Season",
        "Describe": "The top 500 players on the leaderboard can receive corresponding $GWT tokens and NFT rewards",
        "Winners": 500,
        "Endin": "2024 11-15 23:59:59",
        "Reward total": 50000,
        "Reward type": "GWT",
        "Sort": 1,
        "More details URL": "###",
        "Pic": "/GalaxyFlight.jpg",
        "Play and earn URL": "https://t.me/galaxyflightbot?start=999999"
    },
    "10": {
        "id": 10,
        "Title": "GalaxyEscape LEVEL NFT",
        "Describe": "After the ship reaches a certain level, you can obtain NFT",
        "Winners": "unlimited",
        "Endin": "Forever",
        "Reward total": "unlimited",
        "Reward type": "NFT",
        "Sort": 2,
        "More details URL": "###",
        "Pic": "/GalaxyEscape.jpg",
        "Play and earn URL": "https://t.me/galaxyescapebot?start=999999"
    },
    "11": {
        "id": 11,
        "Title": "GalaxyFortress LEVEL RANK",
        "Describe": "Upgrade levels in the game to receive monthly rewards",
        "Winners": 100,
        "Endin": "Forever",
        "Reward total": 50000,
        "Reward type": "GWT",
        "Sort": 3,
        "More details URL": "###",
        "Pic": "/GalaxyFortress.jpg",
        "Play and earn URL": "https://t.me/galaxyfortressbot?start=999999"
    }
}