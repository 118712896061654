import "./App.css";
import AssetsPage from "./pages/assets";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import GameLayout from "./layouts/GameLayout";
import Layout from "./layouts/Layout";
import MainPage from "./pages/main/MainPage";
import EarnPage from "./pages/earn/EarnPage";
import RankPage from "./pages/rank/RankPage";
import TaskPage from "./pages/task/TaskPage";
import FriendsPage from "./pages/friends/FriendsPage";
import GamePage from "./pages/game/GamePage";
import GamePlayPage from "./pages/game/play/GamePlayPage";
import BuyPage from "./pages/buy/BuyPage";
import MePage from "./pages/me/MePage";
import "animate.css";
import { useEffect } from "react";
import HistoryPage from "./pages/history/HistoryPage";
import usePageReload from "./hooks/usePageReload";

// 是否开启 mock 模式
const mockValue = 999;

function App() {
  const [searchParams] = useSearchParams();
  const isMock = Number(searchParams.get("mock")) === mockValue;
  sessionStorage.setItem("isMock", isMock);
  usePageReload();

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<GamePage />} />
        <Route path="earn" element={<EarnPage />} />
        <Route path="rank" element={<RankPage />} />
      </Route>
      <Route path="/task" element={<MainLayout />}>
        <Route index element={<TaskPage />} />
      </Route>
      <Route path="/buy" element={<Layout />}>
        <Route index element={<BuyPage />} />
      </Route>
      <Route path="/game" element={<MainLayout />}>
        <Route index element={<MainPage />} />
      </Route>
      <Route path="/gameplay/cbtc" element={<GameLayout />}>
        <Route index element={<GamePlayPage />} />
      </Route>
      <Route path="/friends" element={<MainLayout />}>
        <Route index element={<FriendsPage />} />
      </Route>
      <Route path="/me" element={<MainLayout />}>
        <Route index element={<MePage />} />
        <Route path="assets" element={<AssetsPage />} />
        <Route path="history" element={<HistoryPage />} />
      </Route>
    </Routes>
  );
}

export default App;
