function TabBarItem(props) {
  const { value, label, icon, isSelect, onClick, disabled } = props;

  return (
    <button
      data-selected="true"
      data-slot="tab"
      tabIndex="0"
      data-key="Earn"
      id="react-aria7709663581-:r5:-tab-Earn"
      aria-selected="true"
      aria-controls="react-aria7709663581-:r5:-tabpanel-Earn"
      role="tab"
      className="select-none z-0 w-full flex group relative justify-center items-center cursor-pointer transition-opacity tap-highlight-transparent data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-30 data-[hover-unselected=true]:opacity-disabled outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 h-8 text-small rounded-none"
      type="button"
      disabled={label === "Club"}
      onClick={() => {
        if (!disabled) {
          onClick?.(value);
        }
      }}
    >
      <span
        className="absolute z-0 h-[2px] w-full bottom-0 shadow-[0_1px_0px_0_rgba(0,0,0,0.05)] rounded-none group-data-[selected=true]:bg-gradient-to-r group-data-[selected=true]:from-[#c88ddb] group-data-[selected=true]:to-[#90d6f8]"
        data-slot="cursor"
        style={isSelect ? { opacity: "1" } : { opacity: 0 }}
      ></span>
      <div
        className="relative z-10 whitespace-nowrap transition-colors text-default-500n group-data-[selected=true]:font-bold"
        data-slot="tabContent"
      >
        <div className="flex items-center w-full space-x-1">
          { icon && <img
            className={`w-[13px] h-[13px] select-none ${
              !isSelect || disabled ? "grayscale" : "grayscale-0"
            }`}
            src={icon}
            alt=""
          />}
          <div
            className={`text-base select-none font-[600] ${
              !isSelect || disabled
                ? "text-[#ffffff] text-opacity-60"
                : "bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] bg-clip-text text-transparent"
            }`}
          >
            {label}
          </div>
        </div>
      </div>
    </button>
  );
}

export default TabBarItem;
