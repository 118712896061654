export default function CopySvg() {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_659)">
      <g filter="url(#filter0_d_1_659)">
      <path d="M59.2812 31.5C59.2812 44.7894 48.5081 55.5625 35.2188 55.5625C21.9294 55.5625 11.1562 44.7894 11.1562 31.5C11.1562 18.2106 21.9294 7.4375 35.2188 7.4375C48.5081 7.4375 59.2812 18.2106 59.2812 31.5Z" fill="black"/>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M35.2188 53.8125C47.5416 53.8125 57.5312 43.8229 57.5312 31.5C57.5312 19.1771 47.5416 9.1875 35.2188 9.1875C22.8959 9.1875 12.9062 19.1771 12.9062 31.5C12.9062 43.8229 22.8959 53.8125 35.2188 53.8125ZM35.2188 55.5625C48.5081 55.5625 59.2812 44.7894 59.2812 31.5C59.2812 18.2106 48.5081 7.4375 35.2188 7.4375C21.9294 7.4375 11.1562 18.2106 11.1562 31.5C11.1562 44.7894 21.9294 55.5625 35.2188 55.5625Z" fill="#c88ddb "/>
      <rect x="26.0312" y="25.375" width="14.4375" height="16.625" rx="3" fill="#c88ddb "/>
      <path fillRule="evenodd" clipRule="evenodd" d="M31.7188 22.75V35.875H42.6562V22.75H31.7188ZM31.2812 21C30.5564 21 29.9688 21.5876 29.9688 22.3125V36.3125C29.9688 37.0374 30.5564 37.625 31.2812 37.625H43.0938C43.8186 37.625 44.4062 37.0374 44.4062 36.3125V22.3125C44.4062 21.5876 43.8186 21 43.0938 21H31.2812Z" fill="#c88ddb "/>
      </g>
      <defs>
      <filter id="filter0_d_1_659" x="0.65625" y="0.9375" width="69.125" height="69.125" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="5.25"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0.5 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_659"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_659" result="shape"/>
      </filter>
      <clipPath id="clip0_1_659">
      <rect width="70" height="70" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}