export default function FriendsActiveSvg() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.203125 18.5C0.203125 14.7129 3.27322 11.6429 7.06032 11.6429C10.8474 11.6429 13.9174 14.7129 13.9174 18.5H0.203125ZM7.06032 10.7857C4.21882 10.7857 1.91743 8.4843 1.91743 5.6429C1.91743 2.8014 4.21882 0.5 7.06032 0.5C9.90172 0.5 12.2031 2.8014 12.2031 5.6429C12.2031 8.4843 9.90172 10.7857 7.06032 10.7857ZM13.3712 12.6999C16.0159 13.3757 18.0042 15.6916 18.1891 18.5H15.6317C15.6317 16.263 14.7747 14.2262 13.3712 12.6999ZM11.6375 10.7488C13.037 9.4933 13.9174 7.6709 13.9174 5.6429C13.9174 4.428 13.6015 3.2869 13.0473 2.2973C15.0105 2.6891 16.4888 4.4211 16.4888 6.5C16.4888 8.8679 14.571 10.7857 12.2031 10.7857C12.0114 10.7857 11.8226 10.7731 11.6375 10.7488Z" fill="url(#paint0_linear_1_702)"/>
      <defs>
      <linearGradient id="paint0_linear_1_702" x1="0.203125" y1="9.5" x2="18.1891" y2="9.5" gradientUnits="userSpaceOnUse">
      <stop stop-color="#c88ddb"/>
      <stop offset="1" stop-color="#90d6f8"/>
      </linearGradient>
      </defs>
    </svg>
  )
}