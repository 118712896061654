export default function QuestionMarkSvg() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.75' y='1' width='14' height='14' rx='5.5' stroke='#90d6f8' />
      <path
        d='M6.98 8.93C6.77 7.36 8.9 7.01 8.9 6.04C8.9 5.43 8.42 5.09 7.63 5.09C7.02 5.09 6.52 5.37 6.03 5.88L5.21 5.13C5.85 4.39 6.73 3.9 7.8 3.9C9.25 3.9 10.31 4.58 10.31 5.91C10.31 7.37 8.12 7.55 8.28 8.93H6.98ZM7.64 11.6C7.12 11.6 6.75 11.23 6.75 10.71C6.75 10.19 7.13 9.83 7.64 9.83C8.14 9.83 8.52 10.19 8.52 10.71C8.52 11.23 8.14 11.6 7.64 11.6Z'
        fill='#90d6f8'
      />
    </svg>
  )
}
