export default function Button({ text, disabled, margin }) {
  return (
    <button
      className="py-3.5 text-center flex-1 rounded-full text-black font-[800] text-[17px]"
      style={{
        background: "linear-gradient(90deg, #c88ddb 0%, #90d6f8 100%)",
        marginLeft: margin
      }}
      disabled={disabled}
    >{ text }</button>
  )
}