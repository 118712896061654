import { useEffect, useState } from "react";
import DailySmallBox from "../DailySmallBox";
import CompletedSvg from "../../../../components/svg/CompletedSvg";

function DailyBonus(props) {
  const { turnOnDailyBonus } = props?.data || {};
  const [boxList, setBoxList] = useState([]);

  useEffect(() => {
    const tempList = [];
    for (let i = 0; i < 5; i++) {
      // const tempStatus = turnOnDailyBonus?.[i];
      turnOnDailyBonus?.forEach((el, idx) => {
        tempList[el-1] = el;
      })
      tempList[i] = 0;
    }
    setBoxList(tempList);
  }, [turnOnDailyBonus]);

  return (
    <div
      id="top_bonus"
      className="mt-1 h-[28px] flex items-center rounded-[0.625rem] px-2.5 py-1.5 backdrop-blur-[0.78125rem]"
      style={{
        backdropFilter: "blur(12.5px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="text-[12px] font-[600] leading-[1.25rem] text-white">
        Daily Bonus
      </div>
      <div className="ml-[8px] mr-[8px] flex flex-1 justify-start">
        {boxList?.slice(0, 5)?.map((item, idx) => {
          return <DailySmallBox key={idx} type={0} status={item} />;
        })}
      </div>
      <div
        className="relative flex items-center h-[21px] justify-center gap-[0.25rem] rounded-[0.375rem] px-2.5 py-1"
        style={{
          background: "linear-gradient(90deg, #c88ddb 0%, #90d6f8 100%)",
          minWidth: "80px",
          height: "21px",
        }}
      >
        <img
          alt="score"
          loading="lazy"
          width="14"
          height="14"
          decoding="async"
          data-nimg="1"
          srcSet="/score-white.svg 1x, /score-white.svg 2x"
          src="/score-white.svg"
          style={{ color: "transparent" }}
        />
        <div className="text-center text-[1rem] font-[600] leading-[1.5rem] text-black">
          +200
        </div>
        {
          turnOnDailyBonus?.length === 5 && (
            <div
              className="w-full h-[21px] flex justify-center items-center absolute top-50% left-50% translate-y-50% m-auto rounded-[0.375rem] backdrop-blur-[1px]"
              style={{
                background: "radial-gradient(47.5% 47.5% at 50% 46.76%, rgba(0, 205, 205, 0.7) 0%, rgba(0, 240, 255, 0) 87%)"
              }}
            >
              <CompletedSvg />
            </div>
          )
        }
      </div>
    </div>
  );
}

export default DailyBonus;
