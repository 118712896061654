export default {
    "1": {
        "id": 1,
        "type": 1,
        "en": "Daily login",
        "cn": "每日登录",
        "avatar": "icon-DailyLogin.svg",
        "link": 1,
        "noticeServer": 1,
        "conditionType": "loginInToday",
        "conditionValue": 1,
        "pikaReward": 40,
        "sort": 1,
        "checkType": 0,
        "checkData": "{}"
    },
    "2": {
        "id": 2,
        "type": 1,
        "en": "Daily Farm 1 time",
        "cn": "挖1次矿",
        "avatar": "icon-FarmTime.svg",
        "link": "{\"page\":3,\"tab\":1,\"btn\":1,\"id\":102}",
        "noticeServer": 0,
        "conditionType": "ploughing",
        "conditionValue": 1,
        "pikaReward": 40,
        "sort": 2,
        "checkType": 0,
        "checkData": "{}"
    },
    "3": {
        "id": 3,
        "type": 1,
        "en": "Daily Farm 3 time",
        "cn": "挖3次矿",
        "avatar": "icon-FarmTime.svg",
        "link": "{\"page\":3,\"tab\":1,\"btn\":1,\"id\":103}",
        "noticeServer": 0,
        "conditionType": "ploughing",
        "conditionValue": 3,
        "pikaReward": 120,
        "sort": 3,
        "checkType": 0,
        "checkData": "{}"
    },
    "4": {
        "id": 4,
        "type": 1,
        "en": "Daily check 1 Box",
        "cn": "打开1个箱子",
        "avatar": "icon-BlueBox.svg",
        "link": 4,
        "noticeServer": 0,
        "conditionType": "checkBox",
        "conditionValue": 1,
        "pikaReward": 40,
        "sort": 4,
        "checkType": 0,
        "checkData": "{}"
    },
    "5": {
        "id": 5,
        "type": 1,
        "en": "Daily check 3 Box",
        "cn": "打开3个箱子",
        "avatar": "icon-YellowBox.svg",
        "link": 5,
        "noticeServer": 0,
        "conditionType": "checkBox",
        "conditionValue": 3,
        "pikaReward": 120,
        "sort": 5,
        "checkType": 0,
        "checkData": "{}"
    },
    "6": {
        "id": 6,
        "type": 1,
        "en": "Daily Invite 1 friend",
        "cn": "邀请好友1名",
        "avatar": "icon-Invite1.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":106}",
        "noticeServer": 0,
        "conditionType": "inviteInToday",
        "conditionValue": 1,
        "pikaReward": 200,
        "sort": 6,
        "checkType": 0,
        "checkData": "{}"
    },
    "8": {
        "id": 8,
        "type": 2,
        "en": "Follow Galaxy X",
        "cn": "绑定Twitter",
        "avatar": "icon-twitter.svg",
        "link": "https://x.com/playGALAXY",
        "noticeServer": 1,
        "conditionType": "bindingTwitter",
        "conditionValue": 1,
        "pikaReward": 500,
        "sort": 7,
        "checkType": 0,
        "checkData": "{}"
    },
    "12": {
        "id": 12,
        "type": 2,
        "en": "Invite 1 friend",
        "cn": "邀请好友1名",
        "avatar": "icon-Invite1.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":112}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 1,
        "pikaReward": 500,
        "sort": 12,
        "checkType": 0,
        "checkData": "{}"
    },
    "13": {
        "id": 13,
        "type": 2,
        "en": "Invite 3 friend",
        "cn": "邀请好友3名",
        "avatar": "icon-Invite3.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":113}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 3,
        "pikaReward": 1500,
        "sort": 13,
        "checkType": 0,
        "checkData": "{}"
    },
    "14": {
        "id": 14,
        "type": 2,
        "en": "Invite 5 friend",
        "cn": "邀请好友5名",
        "avatar": "icon-Invite10.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":114}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 5,
        "pikaReward": 2500,
        "sort": 14,
        "checkType": 0,
        "checkData": "{}"
    },
    "15": {
        "id": 15,
        "type": 2,
        "en": "Invite 10 friend",
        "cn": "邀请好友10名",
        "avatar": "icon-Invite10.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":115}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 10,
        "pikaReward": 5000,
        "sort": 15,
        "checkType": 0,
        "checkData": "{}"
    },
    "16": {
        "id": 16,
        "type": 2,
        "en": "Invite 100 friend",
        "cn": "邀请好友100名",
        "avatar": "icon-Invite10.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":116}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 100,
        "pikaReward": 10000,
        "sort": 16,
        "checkType": 0,
        "checkData": "{}"
    },
    "17": {
        "id": 17,
        "type": 2,
        "en": "Invite 500 friend",
        "cn": "邀请好友500名",
        "avatar": "icon-Invite10.svg",
        "link": "{\"page\":4,\"tab\":1,\"btn\":1,\"id\":117}",
        "noticeServer": 0,
        "conditionType": "invite",
        "conditionValue": 500,
        "pikaReward": 20000,
        "sort": 17,
        "checkType": 0,
        "checkData": "{}"
    },
    "18": {
        "id": 18,
        "type": 2,
        "en": "Bind TG wallet",
        "cn": "绑定TG钱包",
        "avatar": "icon-TGwallet.svg",
        "link": 15,
        "noticeServer": 0,
        "conditionType": "bindingTG",
        "conditionValue": 1,
        "pikaReward": 500,
        "sort": 18,
        "checkType": 0,
        "checkData": "{}"
    },
    "19": {
        "id": 19,
        "type": 2,
        "en": "Join News channel",
        "cn": "加入Galaxy新频道",
        "avatar": "icon-Telegram.svg",
        "link": "https://t.me/GalaxyAnn",
        "noticeServer": 1,
        "conditionType": "joinTGChannel_19",
        "conditionValue": 1,
        "pikaReward": 500,
        "sort": 10,
        "checkType": 1,
        "checkData": "{\"bot_username\":\"pikagatebot\",\"chat_id\":\"@SurvivorAnn\"}"
    }
}