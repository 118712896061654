import { useEffect, useState } from "react";
import { ploughing } from "../../../../api/box";
import { showToast } from "../../../../components/showToast";
import { COMMON_TOAST_TYPE_WARNNING } from "../../../../components/showToast/constance";
import ScoreColorSvg from "../../../../components/svg/ScoreColorSvg";
import { useCountDown, useInterval } from "ahooks";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import { formatCount } from "../../../../utils";

const NO_CLAIM_STATUS = "stop";

const dicBtnTxt = {
  0: "Start Daily Farming",
  1: "Farming",
  2: "Claim",
};

function getBtnStatus(status, nowTime, nextFarmingTime) {
  if (status === "ploughing" && nowTime < nextFarmingTime) {
    // 挖取中状态 -- Famring
    return 1;
  } else if (status === "ploughing" && nowTime > nextFarmingTime) {
    // 可以领取状态  -- Claim
    return 2;
  }
  return 0;
}

function MyPoints(props) {
  const shareText =
    "Decentralized Gaming Mega-ecosystem and Studio. Let's play-and-earn airdrop right now!";
  const tgInvateLink = useSelector((state) => state.common.tgInvateLink);
  const { data, onClaim, onTimeEnd } = props || {};
  const {
    point,
    status,
    nextFarmingTime,
    speed,
    timeGap,
    farming_max,
    serverTime,
  } = data || {};
  const [leftTime, setLeftTime] = useState(0);
  const [countdown, formattedRes] = useCountDown({
    leftTime: leftTime,
    onEnd: onTimeEnd,
  });

  const { hours, minutes, seconds } = formattedRes;
  const [progressVal, setProgressVal] = useState(0);
  const nowTime = Math.floor(serverTime);
  const spTime = Math.floor(Number(speed) * Number(timeGap));

  useInterval(() => {
    const val = (spTime / 60) * 100;
    setProgressVal(val);
  }, 1000);

  const btnStatus = getBtnStatus(status, nowTime, nextFarmingTime);

  async function onClaimBtn() {
    const curTime = Math.floor(Date.now() / 1000);
    try {
      const resp = await ploughing();
      onClaim?.();
    } catch (err) {
      console.log(err);
    }
  }

  function formatNum(num) {
    return num > 9 ? `${num}` : Number(num) ? `0${num}` : "00";
  }

  useEffect(() => {
    setLeftTime((data?.nextFarmingTime - data?.serverTime) * 1000);
  }, [data]);

  const handleForwardTG = (url, txt = "") => {
    const content = `url=${url}&text=${txt}`;
    const shareUrl = `https://t.me/share/url?${content}`;
    window.Telegram.WebApp.openTelegramLink(shareUrl);
  };

  return (
    <div className="card mt-2 flex flex-col rounded-lg bg-gradient-to-r from-[#000000cc] to-[#4a777a66]  px-4 py-2 shadow-md backdrop-blur-lg">
      <div className="flex text-white font-[500] text-[13px] items-center justify-between text-sm">
        <div>My Points</div>
        <div className="relative z-10 px-3 -mr-2">
          <button
            className="z-0 group relative inline-flex items-center justify-center box-border-[#ffffff4d] appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-3 min-w-16 gap-2 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover w-full rounded-full text-sm font-bold text-[#fff] border-1 border-slate-300 mt-0 py-0 h-6"
            type="button"
          >
            <div
              className="flex items-center text-xs font-medium"
              onClick={() => {
                handleForwardTG(tgInvateLink, shareText);
              }}
            >
              Refer frens
              <div className="ml-1 font-bold bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] bg-clip-text text-transparent">
                +100
              </div>
              <div
                className="relative shadow-black/5 shadow-none rounded-large ml-1"
                style={{ maxWidth: "14px" }}
              >
                {/* <img
                  src="/icon-c-color.png"
                  className="relative z-10 opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 shadow-none transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-large ml-1"
                  width="14"
                  height="14"
                  alt="score"
                  data-loaded="true"
                /> */}
                <ScoreColorSvg />
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className=" flex items-center pb-1">
        <div className="flex items-baseline" id="points">
          <div
            data-loaded="true"
            className="group relative overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none mr-2 h-4 rounded-lg"
            style={{ minWidth: "80px", minHeight: "48px" }}
          >
            <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
              <div className="bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] bg-clip-text text-[32px] font-bold text-transparent">
                {/* <CountUp start={0} end={formatCount(point)} duration={1.5} /> */}
                {point || 0}
              </div>
            </div>
          </div>
          <div className="flex items-center text-white text-[16px] font-bold">
            $Galaxy
            <div
              className="relative shadow-black/5 shadow-none rounded-large"
              style={{ maxWidth: "14px" }}
            >
              {/* <img
                src="/score.svg"
                className="relative z-10 opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 shadow-none transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-large ml-1"
                width="14"
                height="14"
                alt="score"
                data-loaded="true"
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div id="home-score" className="-mt-12 flex flex-row-reverse">
        <div
          className="relative shadow-black/5 shadow-none rounded-large"
          // style={{ maxWidth: "80px" }}
        >
          <img
            src="/home-score.png"
            className="relative z-10 opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 shadow-none transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-large"
            width="77"
            height="49"
            alt="home-score"
            data-loaded="true"
          />
        </div>
      </div>
      <div
        data-loaded="true"
        className="group overflow-hidden bg-content3 dark:bg-content2 before:opacity-100 before:absolute before:inset-0 before:-translate-x-full before:border-t before:border-content4/30 before:bg-gradient-to-r before:from-transparent before:via-content4 dark:before:via-default-700/10 before:to-transparent after:opacity-100 after:absolute after:inset-0 after:-z-10 after:bg-content3 dark:after:bg-content2 data-[loaded=true]:!bg-transparent data-[loaded=true]:before:opacity-0 data-[loaded=true]:before:animate-none data-[loaded=true]:after:opacity-0 before:animate-none before:transition-none after:transition-none relative z-10 -mt-4 h-12 rounded-lg"
      >
        <div className="opacity-0 group-data-[loaded=true]:opacity-100 transition-none">
          <div className="relative z-10 -mt-3 px-0">
            <button
              className="z-0 group h-[36px] relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-6 min-w-24 gap-3 [&amp;>svg]:max-w-[theme(spacing.8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none data-[hover=true]:opacity-hover mt-3 w-full rounded-full py-3 text-sm font-bold text-white bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] brightness-100  active:brightness-[0.75]"
              type="button"
              id="earn-btn"
              onClick={onClaimBtn}
            >
              <div className="flex text-black items-center text-lg font-semibold">
                {dicBtnTxt[btnStatus]}
                {btnStatus === 2 && (
                  <>
                    <div
                      className="relative shadow-black/5 shadow-none rounded-large"
                      style={{ maxWidth: "20px" }}
                    >
                      <img
                        src="/score-white.svg"
                        className="relative z-10 opacity-0 shadow-black/5 data-[loaded=true]:opacity-100 shadow-none transition-transform-opacity motion-reduce:transition-none !duration-300 rounded-large ml-2"
                        alt="score"
                        width="20"
                        data-loaded="true"
                      />
                    </div>
                    <span className="ml-3">+ {farming_max}</span>
                  </>
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
      {nowTime < nextFarmingTime && (
        <div className="flex flex-row items-center -mt-2">
          <div className="text-[12px] font-bold text-white">Farming</div>
          <div className="relative ml-1 mt-1 h-[8px] w-[140px] rounded-lg bg-[#fff] text-sm font-bold">
            <div
              className="absolute left-0 top-0 h-full rounded-lg"
              style={{
                width: `${progressVal}%`,
                background: "linear-gradient(90deg, #00FFD1 0%, #FFEE92 100%)",
              }}
            ></div>
          </div>
          <div
            className="relative shadow-black/5 shadow-none rounded-large ml-1 mt-0.5"
            style={{ maxWidth: "13px" }}
          >
            <ScoreColorSvg />
          </div>
          <div className="ml-1.5 text-[12px] font-bold text-white">
            {Math.min(spTime || 0, farming_max)}
          </div>
          <div className="ml-1 text-[12px] font-bold text-white">{`${formatNum(
            hours
          )}h:${formatNum(minutes)}m:${formatNum(seconds)}s`}</div>
        </div>
      )}
    </div>
  );
}

export default MyPoints;
