import dayjs from "dayjs";

export default function HistoryItem({ from, num, point, symbol, time }) {
  const ways = {
    "newUser": "by Invite friends",
    "motherUser": "by Invited",
    "finshTask": "by task",
    "Task": "by task",
    "ploughing": "by Farm and Claim",
    "tapBox": "by Tap Box",
    "checkBox": "by Check Box",
    "taskRankAward": "by Rank reward",
    "admin": "by playandearn",
    "dailyBouns": "by Daily Bouns"
  }

  return (
    <li className="flex items-start py-[15px] px-2 border-b border-[#FFFFFF1A]">
      <div className="pl-2 pr-3 pt-2.5">
        <span
          className="block w-[3px] h-[3px] bg-white -rotate-45 "
          style={{
            boxShadow: "0px 0px 0px 3px rgba(255, 255, 255, 0.2)"
          }}
        ></span>
      </div>
      <div className="text-[#FFFFFFCC] text-sm font-medium">
        <div>{dayjs(time * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
        <div className="flex items-baseline">
          <span className="text-[#c88ddb ] font-bold text-lg mr-2">+{point || 0}</span>
          <span className="text-white mr-1">{symbol || "GALAXY"}</span>
          {from === "newUser" ? `${ways[from]} ${num}per` : ways[from]}
        </div>
      </div>
    </li>
  )
}