import ScoreSvg from "../../../components/svg/ScoreSvg";
import { useRippleEff } from "../../../hooks/useRippleEff";

export default function EarnBtn(props) {
  useRippleEff();
  const { disabled } = props;

  const handleClick = () => {
    if (props?.["Play and earn URL"]) {
      window.Telegram.WebApp.openTelegramLink(props["Play and earn URL"]);
    }
  }
  
  return (
    <button
      disabled={disabled}
      className="relative w-full py-3.5 text-black rounded-full text-[15px] font-[800] flex items-center justify-center space-x-1 ripple"
      style={
        disabled ? {background: `linear-gradient(0deg, #6A7777, #6A7777), linear-gradient(90deg, #c88ddb 0%, #90d6f8 100%)`} : {background: "linear-gradient(90deg, #c88ddb 0%, #90d6f8 100%)", overflow: 'hidden'}
      }
      type="button"
      onClick={handleClick}
    >
      Play can earn&nbsp;
      { props["Reward value"] && (
        <>
          <ScoreSvg />
          <span>+{props["Reward value"]}</span>
        </>
      ) }
      {props["Reward type"]}
      {
        disabled && <img
          className="absolute right-[-15px] bottom-[-6px]"
          width="70"
          height="70"
          src="/pika-ended.png"
          alt="pika-ended"
        />
      }
    </button>
  )
}