import { useEffect, useState } from "react";
import { getMockTaskData } from "../../api/task/mock";
import DescriptionInfo from "./components/DescriptionInfo";
import InviteBtn from "./components/InviteBtn";
import MyReward from "./components/MyReward";
import { getFarmerInvites } from "../../api/friends";
import RankList from "./components/RankList";
import { useJumpChain } from "../../hooks/useJumpChain";
import { createShakeAni } from "../../utils/cssAni";
import TabBar from "../../components/TabBar"
import Club from "./Club";

const linkKeys = [203, 207, 106, 112, 113, 114, 115, 116, 117, 999];

const tabs = [
  { value: "Friends", label: "Friends", icon: "", disabled: false },
  { value: "Club", label: "Club", icon: "", disabled: false }
]

const getLinkData = () => {
  const result = {};
  linkKeys.forEach((item) => {
    result[item] = {
      btnFn: () => {
        createShakeAni("inviteBtn");
      },
    };
  });
  return result;
};

export default function FriendsPage() {
  const [rankList, setRankList] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [ curTab, setCurTab ] = useState("Friends")

  useJumpChain(getLinkData());

  const getPageInfo = async () => {
    const res = await getFarmerInvites();
    setPageInfo(res);
    console.log(res)
  };

  useEffect(() => {
    getPageInfo();
  }, []);

  const onTabChange = (key) => {
    console.log(key);
    setCurTab(key);
    if (key === "Friends") {
      getPageInfo();
    }
  }

  return (
    <div className="min-h-screen select-none p-4 pb-8">
      <TabBar tabs={tabs} value={curTab} onClick={onTabChange} />
      <>
        {
          curTab === "Friends" && <div>
            <MyReward data={pageInfo} />
            <InviteBtn />
            <DescriptionInfo />
            <RankList data={pageInfo} />
          </div>
        }
        { curTab === "Club" && <Club /> }
      </>
    </div>
  );
}
