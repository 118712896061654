import RankItem from "./RankItem";

export default function RankList(props) {
  const { data } = props || {};

  return (
    <>
      <div className="mt-2 flex flex-row px-2.5" id="user-row">
        <div className="w-8 text-center text-sm text-[#FFFFFF99]">#</div>
        <div className="flex-grow text-sm text-[#FFFFFF99]">User</div>
        <div className="w-30 text-sm text-[#FFFFFF99]">⭐ Total</div>
      </div>
      <ul className="pb-28 flex flex-col space-y-2 mt-2 text-white">
        {data?.inviteRanks?.map((item) => (
          <RankItem key={item.rankNumber} data={item} />
        ))}
      </ul>
    </>
  );
}
