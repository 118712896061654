import { formatDateTime } from "../../../utils"

export default function EndsTime({ Endin }) {
  const data = formatDateTime(Endin)
  return (
    <div
      className="mt-2 flex items-center justify-between text-white py-3.5 border-t"
      style={{
        borderTop: "1px solid rgba(255, 255, 255, 0.1)"
      }}
    >
      <div className="text-sm font-bold">Ends in</div>
      {
        data ? (
          <div
            className="flex items-center space-x-1 text-center text-[14px] font-[700] leading-[2rem] bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] bg-clip-text text-transparent"
          >
            <span className="font-bold mt-0.5">{data?.y}</span>
            <span className="font-bold mt-0.5">{data?.md}</span>
            <span className="font-bold mt-0.5">{data?.hms}</span>
          </div>
        ) : <span className="text-[14px]">{Endin}</span>
      }
    </div>
  )
}