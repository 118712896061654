export const URL_GUIDE_KEY = "guide";

/**
 * 客户端最后一次用户操作时间
 */
export const LAST_ACTIVITY_TIIME = "lastActivityTime";

/**
 * 用户休眠多久重新操作时会刷新网站
 */
export const RELOAD_TIME_GAP = 60 * 1000 * 60 * 4;
