import Title from "../../components/Title";
import PikaBot from "./components/PikaBot";

export default function Club() {
  return (
    <div className="min-h-screen pt-8">
      <div>
        <Title title="Verify Your Community" />
        <PikaBot />
      </div>
    </div>
  )
}