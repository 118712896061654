import WinnerSvg from "../../../components/svg/WinnerSvg";

export default function Winners({ winners, total }) {
  return (
    <div className="mb-3 flex items-center space-x-2">
      <div className="flex items-center">
        <div
          className="relative max-w-fit min-w-min inline-flex items-center justify-between box-border whitespace-nowrap px-1 h-6 text-tiny rounded-full text-default-foreground border-1"
          style={{
            border: "1px solid rgba(255, 255, 255, 0.2)"
          }}
        >
          <WinnerSvg />
          <span className="flex-1 text-inherit px-1 pl-0.5 py-1 font-medium text-white">
            <div className="mx-1 font-medium">{winners === "unlimited" ? "∞" : winners} Winners</div>
          </span>
        </div>
      </div>
      <div
          className="relative max-w-fit px-2.5 min-w-min inline-flex items-center justify-between box-border whitespace-nowrap h-6 text-tiny rounded-full text-default-foreground border-1"
          style={{
            border: "1px solid rgba(255, 255, 255, 0.2)"
          }}
        >
          <span className="text-white text-sm">Total：<span className="font-bold bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] text-transparent bg-clip-text">{total === "unlimited" ? "∞" : total}</span></span>
        </div>
    </div>
  )
}