import React from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import GameActiveSvg from '../components/svg/GameActiveSvg'
import GameNormalSvg from '../components/svg/GameNormalSvg'
import TaskActiveBarSvg from '../components/svg/TaskActiveBarSvg'
import TaskNormalBarSvg from '../components/svg/TaskNormalBarSvg'
import TapActiveSvg from '../components/svg/TapActiveSvg'
import TapNormalSvg from '../components/svg/TapNormalSvg'
import MeActiveSvg from '../components/svg/MeActiveSvg'
import MeNormalSvg from '../components/svg/MeNormalSvg'
import FriendsActiveSvg from '../components/svg/FriendsActiveSvg'
import FriendsNormalSvg from '../components/svg/FriendsNormalSvg'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TabBar from './components/TabBar'

function MainTabSelected({ selected }) {
  return (
    selected && (
      <span
        className='absolute z-0 h-[2px] w-[80%] bottom-0 shadow-[0_1px_0px_0_rgba(0,0,0,0.05)] rounded-none bg-[#766ffa]'
        data-slot='cursor'
        style={{ transform: 'none', transformOrigin: '50% 50% 0px' }}
      ></span>
    )
  )
}

function MainTab() {
  const navigate = useNavigate()
  const location = useLocation()
  const isActiveClassName = 'ml-2 text-base font-[600] text-[#766ffa]'
  const notActiveClassName = 'ml-2 text-base font-[600] text-[#626262]'
  return (
    <div className='sticky top-0 z-50 flex items-center justify-between bg-gray-100'>
      <div data-slot='base' className='inline-flex' aria-label='Tabs variants'>
        <div
          data-slot='tabList'
          className='flex p-1 h-fit items-center flex-nowrap overflow-x-scroll scrollbar-hide bg-transparent dark:bg-transparent rounded-none gap-0'
          id='react-aria6642947923-:r4l:'
          aria-label='Tabs variants'
          role='tablist'
          aria-orientation='horizontal'
        >
          <button
            data-slot='tab'
            tabIndex='-1'
            data-key='Deals'
            id='react-aria6642947923-:r4l:-tab-Deals'
            aria-selected='false'
            role='tab'
            className='z-0 w-full px-3 py-1 flex group relative justify-center items-center cursor-pointer transition-opacity tap-highlight-transparent data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-30 data-[hover-unselected=true]:opacity-disabled outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 h-8 text-small rounded-none'
            type='button'
            onClick={() => navigate('/')}
          >
            <MainTabSelected selected={location.pathname === '/'} />
            <div
              className='relative z-10 whitespace-nowrap transition-colors text-default-500 group-data-[selected=true]:text-[#766ffa] group-data-[selected=true]:font-bold'
              data-slot='tabContent'
            >
              <div className='flex items-center w-14'>
                <img
                  alt='Deals'
                  loading='lazy'
                  width='14'
                  height='14'
                  decoding='async'
                  data-nimg='1'
                  src={
                    location.pathname === '/'
                      ? '/fb-winner-blue.svg'
                      : '/fb-winner-grey.svg'
                  }
                  style={{ color: 'transparent' }}
                />
                <div
                  className={
                    location.pathname === '/'
                      ? isActiveClassName
                      : notActiveClassName
                  }
                >
                  Deals
                </div>
              </div>
            </div>
          </button>
          <button
            data-slot='tab'
            tabIndex='-1'
            data-key='Tap'
            id='react-aria6642947923-:r4l:-tab-Tap'
            aria-selected='false'
            role='tab'
            className='z-0 w-full px-3 py-1 flex group relative justify-center items-center cursor-pointer transition-opacity tap-highlight-transparent data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-30 data-[hover-unselected=true]:opacity-disabled outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 h-8 text-small rounded-none'
            type='button'
            onClick={() => navigate('/game')}
          >
            <MainTabSelected selected={location.pathname === '/game'} />
            <div
              className='relative z-10 whitespace-nowrap transition-colors text-default-500 group-data-[selected=true]:text-[#766ffa] group-data-[selected=true]:font-bold'
              data-slot='tabContent'
            >
              <div className='flex items-center w-14'>
                <img
                  alt='Tap'
                  loading='lazy'
                  width='14'
                  height='14'
                  decoding='async'
                  data-nimg='1'
                  src={
                    location.pathname === '/game' ? '/tap.svg' : '/tap-grey.svg'
                  }
                  style={{ color: 'transparent' }}
                />
                <div
                  className={
                    location.pathname === '/game'
                      ? isActiveClassName
                      : notActiveClassName
                  }
                >
                  Game
                </div>
              </div>
            </div>
          </button>
          <button
            data-slot='tab'
            tabIndex='0'
            data-key='Earn'
            id='react-aria6642947923-:r4l:-tab-Earn'
            aria-selected='true'
            role='tab'
            className='z-0 w-full px-3 py-1 flex group relative justify-center items-center cursor-pointer transition-opacity tap-highlight-transparent data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-30 data-[hover-unselected=true]:opacity-disabled outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 h-8 text-small rounded-none'
            type='button'
            data-selected='true'
            aria-controls='react-aria6642947923-:r4l:-tabpanel-Earn'
            onClick={() => navigate('/earn')}
          >
            <MainTabSelected selected={location.pathname === '/earn'} />
            <div
              className='relative z-10 whitespace-nowrap transition-colors text-default-500 group-data-[selected=true]:text-[#766ffa] group-data-[selected=true]:font-bold'
              data-slot='tabContent'
            >
              <div className='flex items-center w-14'>
                <img
                  alt='Earn'
                  loading='lazy'
                  width='14'
                  height='14'
                  decoding='async'
                  data-nimg='1'
                  src={
                    location.pathname === '/earn'
                      ? '/score.svg'
                      : '/score-grey.svg'
                  }
                  style={{ color: 'transparent' }}
                />
                <div
                  className={
                    location.pathname === '/earn'
                      ? isActiveClassName
                      : notActiveClassName
                  }
                >
                  Earn
                </div>
              </div>
            </div>
          </button>
          <button
            data-slot='tab'
            tabIndex='-1'
            data-key='Rank'
            id='react-aria6642947923-:r4l:-tab-Rank'
            aria-selected='false'
            role='tab'
            className='z-0 w-full px-3 py-1 flex group relative justify-center items-center cursor-pointer transition-opacity tap-highlight-transparent data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-30 data-[hover-unselected=true]:opacity-disabled outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 h-8 text-small rounded-none'
            type='button'
            onClick={() => navigate('/rank')}
          >
            <MainTabSelected selected={location.pathname === '/rank'} />
            <div
              className='relative z-10 whitespace-nowrap transition-colors text-default-500 group-data-[selected=true]:text-[#766ffa] group-data-[selected=true]:font-bold'
              data-slot='tabContent'
            >
              <div className='flex items-center w-14'>
                <img
                  alt='Rank'
                  loading='lazy'
                  width='14'
                  height='14'
                  decoding='async'
                  data-nimg='1'
                  src={
                    location.pathname === '/rank'
                      ? '/leadboard-blue.svg'
                      : '/leadboard.svg'
                  }
                  style={{ color: 'transparent' }}
                />
                <div
                  className={
                    location.pathname === '/rank'
                      ? isActiveClassName
                      : notActiveClassName
                  }
                >
                  Rank
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
      <img
        alt='history'
        loading='lazy'
        width='50'
        height='50'
        decoding='async'
        data-nimg='1'
        className='mr-2'
        src='/history.png'
        style={{ color: 'transparent' }}
      />
    </div>
  )
}

function NavTab() {
  const tabs = [
    {
      path: '/game',
      name: 'Play and earn',
      svg: [<GameActiveSvg />, <GameNormalSvg />],
    },
    {
      path: '/task',
      name: 'Task',
      svg: [<TaskActiveBarSvg />, <TaskNormalBarSvg />],
    },
    { path: '/', name: 'Tap-Tap', svg: [<TapActiveSvg />, <TapNormalSvg />] },
    {
      path: '/friends',
      name: 'Friends',
      svg: [<FriendsActiveSvg />, <FriendsNormalSvg />],
    },
    { path: '/me', name: 'Me', svg: [<MeActiveSvg />, <MeNormalSvg />] },
  ]
  return <TabBar tabs={tabs} />
}

export default function Layout() {
  const location = useLocation()
  const pathname = location.pathname
  const isHome = pathname === '/'

  return (
    <div data-overlay-container='true'>
      <ToastContainer />
      <div
        role='region'
        aria-label='Notifications (F8)'
        tabIndex='-1'
        style={{ pointerEvents: 'none' }}
      >
        <ol
          tabIndex='-1'
          className='fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]'
        ></ol>
      </div>
      <div
        className='mx-auto flex min-h-screen max-w-3xl flex-col relative'
        style={{
          backgroundColor: `#13161e`,
        }}
      >
        <main
          className='flex-1 h-screen'
          id='main_layout_container'
          style={{ zIndex: 999 }}
        >
          <Outlet />
          <NavTab />
        </main>


      </div>
    </div>
  )
}
