export default function TaskActiveBarSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.29835 0H14.7017C16.5208 0 18 1.48002 18 3.29835V11.3333H14.1333C12.5892 11.3333 11.3333 12.5892 11.3333 14.1333V18H3.29835C1.47917 18 0 16.52 0 14.7017V3.29835C0 1.47917 1.48002 0 3.29835 0ZM9.00011 11.6041L12.3309 8.27328C12.7909 7.81327 12.791 7.06818 12.3309 6.60819C11.8709 6.1482 11.1259 6.1482 10.6659 6.60819L8.16759 9.10654L7.3351 8.27405C6.87509 7.81406 6.13009 7.81404 5.6701 8.27405C5.21011 8.73406 5.21011 9.47906 5.6701 9.93905L7.3351 11.6041C7.79511 12.064 8.54012 12.064 9.00011 11.6041ZM13 18V14.4843V14.4835C13 13.6659 13.666 13 14.4837 13H18L13 18Z" fill="url(#paint0_linear_4481_930)"/>
      <defs>
      <linearGradient id="paint0_linear_4481_930" x1="0" y1="9" x2="18" y2="9" gradientUnits="userSpaceOnUse">
      <stop stop-color="#c88ddb"/>
      <stop offset="1" stop-color="#90d6f8"/>
      </linearGradient>
      </defs>
    </svg>
  )
}