import { Tooltip } from "react-tippy";
import RankActiveSvg from "../../../components/svg/RankActiveSvg";
import RankNormalSvg from "../../../components/svg/RankNormalSvg";
import TaskActiveSvg from "../../../components/svg/TaskActiveSvg";
import TaskNormalSvg from "../../../components/svg/TaskNormalSvg";
import QuestionMarkSvg from "../../../components/svg/QuestionMarkSvg";

function TabBar(props) {
  const { onClick, tab } = props;
  function RankDesc () {
    return (
      <div className="text-left text-sm leading-4 flex flex-col space-y-2">
        <p>1. Rank is ranked based on the increase in Galaxy during the cycle</p>
        <p>2. Each natural week is a Rank cycle</p>
        <p>3. Galaxy calculates the time from 0:00 every Monday to 20:00 every Sunday (UTC+8)</p>
        <p>4. Every Sunday at 20:00, a snapshot of Rank ranking will be taken</p>
        <p>5. After the snapshot, the system will distribute GWT to the user, which can be viewed on the ME page</p>
        <p>6. The top 100 users in the ranking will receive a total reward of 15000 GWT</p>
      </div>
    )
  }
  return (
    <div className="flex items-center">
      <div data-slot="base" className="inline-flex relative" aria-label="Tabs variants">
        <div
          data-slot="tabList"
          className="flex h-fit items-center space-x-5 flex-nowrap scrollbar-hide bg-transparent dark:bg-transparent rounded-none gap-0"
          id="react-aria7709663581-:r5:"
          aria-label="Tabs variants"
          role="tablist"
          aria-orientation="horizontal"
        >
          <button
            data-selected="true"
            data-slot="tab"
            tabIndex="0"
            data-key="Earn"
            id="react-aria7709663581-:r5:-tab-Earn"
            aria-selected="true"
            aria-controls="react-aria7709663581-:r5:-tabpanel-Earn"
            role="tab"
            className="z-0 w-full flex group relative justify-center items-center cursor-pointer transition-opacity tap-highlight-transparent data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-30 data-[hover-unselected=true]:opacity-disabled outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 h-8 text-small rounded-none"
            type="button"
            onClick={() => {
              onClick?.("task");
            }}
          >
            <span
              className="absolute z-0 h-[2px] w-[100%] bottom-0 shadow-[0_1px_0px_0_rgba(0,0,0,0.05)] rounded-none group-data-[selected=true]:bg-gradient-to-r group-data-[selected=true]:from-[#c88ddb] group-data-[selected=true]:to-[#90d6f8]"
              data-slot="cursor"
              style={tab === 'task' ? { opacity: "1" } : { opacity: 0 }}
            ></span>
            <div
              className="relative z-10 whitespace-nowrap transition-colors text-default-500n group-data-[selected=true]:font-bold"
              data-slot="tabContent"
            >
              <div className="flex items-center w-14">
                {tab === "task" ? <TaskActiveSvg /> : <TaskNormalSvg />}
                <div className={`ml-2 text-base font-[600] ${tab === 'task' ? 'bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] bg-clip-text text-transparent' : 'text-[#ffffff] text-opacity-60'}`}>
                  Task
                </div>
              </div>
            </div>
          </button>
          <button
            data-selected="true"
            data-slot="tab"
            tabIndex="0"
            data-key="Earn"
            id="react-aria7709663581-:r5:-tab-Earn"
            aria-selected="true"
            aria-controls="react-aria7709663581-:r5:-tabpanel-Earn"
            role="tab"
            className="z-0 w-full flex group relative justify-center items-center cursor-pointer transition-opacity tap-highlight-transparent data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-30 data-[hover-unselected=true]:opacity-disabled outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 h-8 text-small rounded-none"
            type="button"
            onClick={() => {
              onClick?.("rank");
            }}
          >
            <span
              className="absolute z-0 h-[2px] w-[100%] bottom-0 shadow-[0_1px_0px_0_rgba(0,0,0,0.05)] rounded-none group-data-[selected=true]:bg-gradient-to-r group-data-[selected=true]:from-[#c88ddb] group-data-[selected=true]:to-[#90d6f8]"
              data-slot="cursor"
              style={tab === 'rank' ? { opacity: "1" } : { opacity: 0 }}
            ></span>
            <div
              className="relative z-10 whitespace-nowrap transition-colors text-default-500n group-data-[selected=true]:font-bold"
              data-slot="tabContent"
            >
              <div className="flex items-center w-14">
                {tab === "rank" ? <RankActiveSvg /> : <RankNormalSvg />}
                <div className={`ml-2 text-base font-[600] ${tab === 'rank' ? 'bg-gradient-to-r from-[#c88ddb] to-[#90d6f8] bg-clip-text text-transparent' : 'text-[#ffffff] text-opacity-60'}`}>
                  Rank
                </div>
              </div>
            </div>
          </button>
        </div>
        <Tooltip
          style={{
            position: "absolute",
            right: "-24px",
            bottom: "8px",
          }}
          trigger="mouseenter"
          touchHold
          html={<RankDesc />}
          arrow={true}
          animation="scale"
          position="bottom"
        >
          <QuestionMarkSvg />
        </Tooltip>
      </div>
    </div>
  );
}

export default TabBar;
